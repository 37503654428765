import { Component } from '@angular/core';
import { DailyQuizComponentComponent } from '../../components/daily-quiz-component/daily-quiz-component.component';

@Component({
  selector: 'app-daily-quiz-page',
  standalone: true,
  imports: [DailyQuizComponentComponent],
  templateUrl: './daily-quiz-page.component.html',
  styleUrl: './daily-quiz-page.component.scss'
})
export class DailyQuizPageComponent {

}
