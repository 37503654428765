<mat-card style="gap: 0px;">
  <mat-card-header>
    <div class="user-portrait">
      <div mat-card-avatar class="avatar-container">
        <img [src]="this.userForumTitle?.avatarURL" alt="User Avatar">
        <div class="avatar-level" >
          {{this.userForumTitle?.avatarLevel}}
        </div>
        @if ((this.userForumTitle?.avatarStreak ?? 0) > 0){
          <div class="avatar-streak">
            <mat-icon style="color: orange">whatshot</mat-icon>
            <span class="streak-text">{{this.userForumTitle?.avatarStreak}}</span>
          </div>
        }
        
        
           
      </div>
    </div>
    <div class="avatar-details">
      <mat-card-title [ngStyle]="{'color': this.userForumTitle?.avatarCrownColor}">
        @if (this.displayCrown){
          <img [src]="this.userForumTitle?.avatarCrownURL" height="15px" alt="Avatar Crown"> 
        }
        {{ this.userForumTitle?.avatarUsername }}
      </mat-card-title>
      <mat-card-subtitle [ngStyle]="{'color': this.userForumTitle?.avatarCrownColor}">
        {{ this.userForumTitle?.avatarRank }} 
        <img class="avatar-flag" [src]="this.userForumTitle?.avatarFlagURL" height="10px" alt="Avatar Flag">
        
      </mat-card-subtitle>
      <mat-card-subtitle>
        
      </mat-card-subtitle>
    </div>
  </mat-card-header>
</mat-card>
