<form [formGroup]="cardioInputForm" (ngSubmit)="onSubmit()">
    <h2>Log your cardio!</h2>

    <mat-form-field appearance="fill">
        <mat-label>Cardio Type</mat-label>
        <mat-select formControlName="cardioName">
            <mat-option *ngFor="let name of cardioNameList" [value]="name">
                {{ name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" *ngIf="cardioInputForm.get('cardioName')?.value">
        <mat-label>Cardio Location</mat-label>
        <mat-select formControlName="cardioLocation">
            <mat-option *ngFor="let location of cardioLocationList" [value]="location">
                {{ location }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div formGroupName="cardioTime" *ngIf="cardioInputForm.get('cardioLocation')?.value">
        <mat-form-field appearance="fill">
            <mat-label>Hours (optional)</mat-label>
            <mat-select formControlName="hours">
                <mat-option *ngFor="let hour of hourOptions" [value]="hour">
                    {{ hour }} {{ hour <= 1 ? 'hour' : 'hours' }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Minutes</mat-label>
            <mat-select formControlName="minutes">
                <mat-option *ngFor="let minute of minuteOptions" [value]="minute">
                    {{ minute }} minutes
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <mat-form-field appearance="fill" *ngIf="cardioInputForm.get('cardioLocation')?.value">
        <mat-label>Cardio Distance (in miles or km)</mat-label>
        <mat-select formControlName="cardioDistance">
            <mat-option *ngFor="let distance of distanceOptions" [value]="distance">
                {{ distance }} miles
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" [disabled]="cardioInputForm.invalid" class="disabled-button">
        Submit
    </button>    
</form>

