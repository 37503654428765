import { Component, inject } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { RouterOutlet } from '@angular/router';



@Component({
  selector: 'app-top-toolbar',
  standalone: true,
  imports: [
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule
  ],
  templateUrl: './top-toolbar.component.html',
  styleUrl: './top-toolbar.component.scss'
})
export class TopToolbarComponent {
  authService = inject(AuthService);
  heroImage = '/assets/heroImage.png';
  mainLogo = '/assets/mainLogo.png';
  router = inject(Router);

  isActive(route: string): boolean {
    return this.router.url === route;
  }

  submitLogout() {
    this.authService.logout();
  }

}
