import { Component, OnInit, Input, inject } from '@angular/core';
import { UserInterface } from '../../models/user.interface';
import { doc, getDoc } from 'firebase/firestore';
import { Firestore } from '@angular/fire/firestore';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-daily-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-daily-progress.component.html',
  styleUrl: './user-daily-progress.component.scss'
})
export class UserDailyProgressComponent implements OnInit {
  @Input() userInterface: UserInterface | null | undefined;
  firestore = inject(Firestore);
  readonly totalChallengesPerDay = 9;
  readonly totalPointsPerDay = 18;

  UserDailyStats = {
    totalPointsEarned: 0,
    totalPointsAvailable: this.totalPointsPerDay,
    totalChallengesCompleted: 0,
    totalChallengesAvailable: this.totalChallengesPerDay,
    winPercentChallenges: 0,
    winPercentPoints: 0
  };

  async ngOnInit(): Promise<void> {
    const currentDate = this.today();

    const docRefUserDailyChallenges = doc(this.firestore, `user-daily-challenges/${this.userInterface?.uid}/daily-personal-challenges/${currentDate}`);
    const docSnapUserDailyChallenges = await getDoc(docRefUserDailyChallenges);


    const docRefCommunityDailyChallenges = doc(this.firestore, `user-daily-challenges/${this.userInterface?.uid}/daily-community-challenges/${currentDate}`);
    const docSnapCommunityDailyChallenges = await getDoc(docRefCommunityDailyChallenges);

    if (docSnapUserDailyChallenges.exists()) {
      await this.processChallengesForDay(docSnapUserDailyChallenges.data());
    } else {
      console.log('No personal challenges found for today.');
    }

    if (docSnapCommunityDailyChallenges.exists()) {
      await this.processChallengesForDay(docSnapCommunityDailyChallenges.data());
    } else {
      console.log('No community challenges found for today.');
    }

  }

  async processChallengesForDay(data: any) {
    let dayChallengesCompleted = 0;
    let dayPointsEarned = 0;

    const challenges = data['challenges'] || [];

    challenges.forEach((challenge: any) => {
      if (challenge.challenge_status) {
        dayChallengesCompleted++;
        dayPointsEarned += this.getPointsByDifficulty(challenge.challenge_difficulty);
      }
    });

    // Update Daily stats
    this.UserDailyStats.totalChallengesCompleted += dayChallengesCompleted;
    this.UserDailyStats.totalPointsEarned += dayPointsEarned;
    this.UserDailyStats.winPercentChallenges = this.UserDailyStats.totalChallengesCompleted / this.UserDailyStats.totalChallengesAvailable;
    this.UserDailyStats.winPercentPoints = this.UserDailyStats.totalPointsEarned / this.UserDailyStats.totalPointsAvailable;
    console.log(this.UserDailyStats);
    
  }

  getPointsByDifficulty(difficulty: string): number {
    switch (difficulty) {
      case 'easy': return 1;
      case 'medium': return 2;
      case 'hard': return 3;
      default: return 0;
    }
  }

  helperRoundingFunction(value: number){
    return Math.round(value);
  }



  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

