import { ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes), provideFirebaseApp(() => initializeApp({"projectId":"werkit-dev","appId":"1:162223374646:web:674c5f4900fad7d3c87397","storageBucket":"werkit-dev.appspot.com","apiKey":"AIzaSyAXkgSuNJUjsF7lczOHWQcw_aG45iiil_s","authDomain":"werkit-dev.firebaseapp.com","messagingSenderId":"162223374646","measurementId":"G-DGVLGBC3D1"})), provideAuth(() => getAuth()), provideFirestore(() => getFirestore())
  ]
};
