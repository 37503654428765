<app-top-toolbar></app-top-toolbar>

<div *ngIf="isLoading" class="loading-spinner-container">
  <mat-spinner></mat-spinner> <!-- Angular Material Spinner -->
</div>

<div *ngIf="!isLoading">
  <router-outlet></router-outlet>
  <div class="spacer" style="height: 7vh;"></div>
  <app-bottom-navigation-bar *ngIf="!isLandingPage()"></app-bottom-navigation-bar>
</div>
