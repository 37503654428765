import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-profile-redirect',
  template: '',
})
export class ProfileRedirectComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    const user = this.authService.currentUserSig();
    if (user) {
      this.router.navigate(['/profile', user.username]);
    } else {
      // Handle the case where the user is not authenticated
      this.router.navigate(['/']);
    }
  }
}
