import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Firestore, doc, getDoc, setDoc, updateDoc } from '@angular/fire/firestore';
import { UserInterface } from '../../models/user.interface';
import { AuthService } from '../../services/auth.service';
import { ChallengeModuleInterface } from '../../models/challengeModule.interface';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ChallengeDialogComponent } from '../challenge-dialog/challenge-dialog.component';
import { ChallengeFormDialogComponent } from '../add-challenge-dialog/add-challenge-dialog.component';

@Component({
  selector: 'app-challenge-input-module',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatCardModule, MatButtonModule, MatSelectModule, MatIconModule, MatDialogModule],
  templateUrl: './challenge-input-module.component.html',
  styleUrls: ['./challenge-input-module.component.scss']
})
export class ChallengeInputModuleComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  firestore = inject(Firestore);
  authService = inject(AuthService);
  dialog = inject(MatDialog);
  userInterface: UserInterface | null | undefined;
  challenges: ChallengeModuleInterface[] = [];

  async getUserChallengeDeck() {
    if (this.userInterface && this.userInterface.uid) {
      const userChallengeDeckDocRef = doc(this.firestore, 'user-challenge-deck', this.userInterface.uid);
      const userChallengeDeckSnap = await getDoc(userChallengeDeckDocRef);

      if (userChallengeDeckSnap.exists()) {
        const data = userChallengeDeckSnap.data();
        this.challenges = [
          data['easy'] as ChallengeModuleInterface,
          data['medium'] as ChallengeModuleInterface,
          data['hard'] as ChallengeModuleInterface
        ];
      } else {
        const emptyChallenges = {
          'easy': { challenge_id: 1, challenge_difficulty: 'easy', challenge_type: '', challenge_title: '', challenge_status: false },
          'medium': { challenge_id: 2, challenge_difficulty: 'medium', challenge_type: '', challenge_title: '', challenge_status: false },
          'hard': { challenge_id: 3, challenge_difficulty: 'hard', challenge_type: '', challenge_title: '', challenge_status: false }
        };
        await setDoc(userChallengeDeckDocRef, emptyChallenges);
        this.challenges = Object.values(emptyChallenges) as ChallengeModuleInterface[];
      }
    }
  }

  ngOnInit(): void {
    this.userInterface = this.authService.currentUserSig();
    this.getUserChallengeDeck();
  }

  async deleteChallenge(challenge: ChallengeModuleInterface) {
    const updatedFields: ChallengeModuleInterface = {
      challenge_id: challenge.challenge_id,
      challenge_difficulty: challenge.challenge_difficulty,
      challenge_title: '',
      challenge_type: '',
      challenge_status: false
    };
    this.challenges = this.challenges.map(c => c.challenge_id === challenge.challenge_id ? updatedFields : c);
    const challengeDocRef = doc(this.firestore, 'user-challenge-deck', this.userInterface!.uid);
    await updateDoc(challengeDocRef, { [challenge.challenge_difficulty]: updatedFields });
  }

  openDeleteConfirmationDialog(challenge: ChallengeModuleInterface) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: { challenge }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === true) {
        await this.deleteChallenge(challenge);
      }
    });
  }

  openAddChallengeDialog(challengeDifficulty: 'easy' | 'medium' | 'hard') {
    const dialogRef = this.dialog.open(ChallengeFormDialogComponent, {
      width: '250px',
      data: { challengeDifficulty }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        const currentDate = this.today();
        const challenge = this.challenges.find(c => c.challenge_difficulty === challengeDifficulty);
        if (challenge) {
          challenge.challenge_title = result.challenge_title;
          challenge.challenge_type = result.challenge_type;
          challenge.challenge_status = false;

          const challengeDeckDocRef = doc(this.firestore, 'user-challenge-deck', this.userInterface!.uid);
          const challengeDailyDocRef = doc(this.firestore, `user-daily-challenges/${this.userInterface!.uid}/daily-personal-challenges/${currentDate}`);
          await updateDoc(challengeDeckDocRef, { [challengeDifficulty]: challenge });
          // await updateDoc(challengeDailyDocRef, { [challengeDifficulty]: challenge });
        }
      }
    });
  }

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  capitalizeFirstLetter(text: string | null): string {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  challengeInputForm = this.formBuilder.group({
    challenge_difficulty: ['', Validators.required],
    challenge_type: ['', Validators.required],
    challenge_title: ['', [Validators.required, Validators.minLength(5)]]
  });
}

