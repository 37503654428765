import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-daily-quiz-pie',
  standalone: true,
  templateUrl: './daily-quiz-pie.component.html',
  styleUrls: ['./daily-quiz-pie.component.scss']
})
export class DailyQuizPieComponent implements OnInit {
  @Input() userQuizStats!: {
    quizDates: string[],
    morningFeelings: string[],
    morningMotivation: string[],
  };

  @ViewChild('feelingsChart', { static: true }) feelingsChartRef!: ElementRef<HTMLCanvasElement>;
  @ViewChild('motivationChart', { static: true }) motivationChartRef!: ElementRef<HTMLCanvasElement>;

  feelingsChart: any;
  motivationChart: any;

  colorPrimary: string = "rgb(61, 55, 153, 1)";
  colorPrimaryFill: string = "rgb(61, 55, 153, 0.25)";
  colorSecondary: string = "rgb(128, 125, 174, 1)";
  colorText: string = '#FBFBFD';
  colorBorder: string = '#807DAE';

  colorRed: string = 'lightcoral';
  colorGreen: string = 'lightgreen';

  ngOnInit(): void {
    Chart.register(...registerables);
    this.initializeFeelingsChart();
    this.initializeMotivationChart();
  }

  initializeFeelingsChart(): void {
    const feelingsData = this.calculateData(this.userQuizStats.morningFeelings);

    this.feelingsChart = new Chart(this.feelingsChartRef.nativeElement, {
      type: 'pie',
      data: {
        labels: Object.keys(feelingsData),
        datasets: [{
          data: Object.values(feelingsData),
          backgroundColor: [this.colorPrimary, this.colorSecondary, '#90EE90', this.colorRed],
        }]
      },
      options: {
        responsive: true,
        aspectRatio: 1,
        plugins: {
          legend: {
            position: 'bottom',
          }
        },
        
      }
    });
  }

  initializeMotivationChart(): void {
    const motivationData = this.calculateData(this.userQuizStats.morningMotivation);

    this.motivationChart = new Chart(this.motivationChartRef.nativeElement, {
      type: 'pie',
      data: {
        labels: Object.keys(motivationData),
        datasets: [{
          data: Object.values(motivationData),
          backgroundColor: [this.colorPrimary, this.colorSecondary, this.colorRed, this.colorGreen],
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          }
        }
      }
    });
  }

  calculateData(dataArray: string[]): { [key: string]: number } {
    return dataArray.reduce((acc: { [key: string]: number }, currentValue: string) => {
      if (currentValue !== 'N/A') {
        acc[currentValue] = (acc[currentValue] || 0) + 1;
      }
      return acc;
    }, {});
  }

  ngOnDestroy(): void {
    if (this.feelingsChart) this.feelingsChart.destroy();
    if (this.motivationChart) this.motivationChart.destroy();
  }
}

