import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Firestore } from '@angular/fire/firestore';
import { UserInterface } from '../../models/user.interface';
import { UserClassificationService } from '../../services/user-classification.service';
import { UserTitle } from '../../models/userTitle.model';
import { UserTitleComponent } from '../../shared/user-title/user-title.component';
import { ChallengeInputModuleComponent } from '../../components/challenge-input-module/challenge-input-module.component';
import { doc, getDoc } from 'firebase/firestore';

@Component({
  selector: 'app-profile-page',
  standalone: true,
  imports: [UserTitleComponent, ChallengeInputModuleComponent],
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  userInterface: UserInterface | undefined;
  profileInterface: UserInterface | undefined;
  authService = inject(AuthService);
  firestore = inject(Firestore);
  username!: string | null;
  titleProfile: UserTitle | undefined;

  constructor(
    private route: ActivatedRoute,
    private userClassificationService: UserClassificationService
  ) {}

  ngOnInit(): void {
    this.userInterface = this.authService.currentUserSig() || undefined;

    this.route.paramMap.subscribe(params => {
      this.username = params.get('username') || this.userInterface?.username || null;
      this.logUsername();
      if (this.username) {
        this.getUserProfile().then(profileInterface => {
          if (profileInterface) {
            this.titleProfile = this.userClassificationService.userClassification(profileInterface);
          }
        }).catch(error => {
          console.error('Error getting user profile:', error);
        });
      }
    });
  }

  async getUserProfile(): Promise<UserInterface | undefined> {
    const usernameUidDocRef = doc(this.firestore, `master-usernames/${this.username}`);
    const usernameUidSnapshot = await getDoc(usernameUidDocRef);

    if (usernameUidSnapshot.exists()) {
      const usernameUidData = usernameUidSnapshot.data();
      const profileUid = usernameUidData['uid'];

      const profileInterfaceDocRef = doc(this.firestore, `user-interfaces/${profileUid}`);
      const profileInterfaceSnapshot = await getDoc(profileInterfaceDocRef);

      if (profileInterfaceSnapshot.exists()) {
        this.profileInterface = profileInterfaceSnapshot.data() as UserInterface;
        console.log('profile interface:', this.profileInterface);
        return this.profileInterface;
      } else {
        console.error('No such document in user-interfaces');
        return undefined;
      }
    } else {
      console.error('No such document in master-usernames');
      return undefined;
    }
  }

  logUsername(): void {
    console.log(this.username);
  }
}


