import { Component, Input, OnChanges, SimpleChanges, signal } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { UserTitle } from '../../models/userTitle.model';
import { UserModalComponent } from './subcomponents/user-modal/user-modal.component';
import { UserInterface } from '../../models/user.interface';
import { UserClassificationService } from '../../services/user-classification.service';

@Component({
  selector: 'app-user-title',
  standalone: true,
  imports: [MatCardModule, MatIconModule, CommonModule, UserModalComponent],
  templateUrl: './user-title.component.html',
  styleUrls: ['./user-title.component.scss']
})
export class UserTitleComponent implements OnChanges {
  avatarTitle?: UserTitle;
  @Input() avatarInterface?: UserInterface;
  @Input() currentUserProfile?: boolean;
  @Input() onProfilePage?: boolean;

  constructor(
    private userClassificationService: UserClassificationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['avatarInterface'] && this.avatarInterface) {
      console.log('Avatar interface changed, recalculating title...');
      this.avatarTitle = this.userClassificationService.userClassification(this.avatarInterface);
    }
  }

  isModalOpen = signal(false);
  
  controllerUserModal() {
    this.isModalOpen.set(!this.isModalOpen());
  }
}
