import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Firestore, setDoc, doc, getDoc, updateDoc } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../models/user.interface';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

import { Router, RouterModule } from '@angular/router';

import { UserTitleComponent } from "../../shared/user-title/user-title.component";
import { profileDoodleman } from '../../models/avatars/doodleman.profile';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';

import { DailyQuizStatDisplayComponent } from '../daily-quiz-stat-display/daily-quiz-stat-display.component';

@Component({
  selector: 'app-daily-quiz-component',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    UserTitleComponent,
    MatOptionModule,
    CommonModule,
    MatSelectModule,
    RouterModule,
    SharedMaterialModule,
    DailyQuizStatDisplayComponent
  ],
  templateUrl: './daily-quiz-component.component.html',
  styleUrls: ['./daily-quiz-component.component.scss']
})
export class DailyQuizComponentComponent implements OnInit {
  userInterface: UserInterface | null | undefined;
  formBuilder = inject(FormBuilder);
  firestore = inject(Firestore);
  authService = inject(AuthService);
  
  loadingMorningQuiz: boolean = true;
  flagQuizMorning: boolean = false;
  setActiveQuizMorning: boolean = false;

  loadingNightQuiz: boolean = true;
  setActiveQuizNight: boolean = false;
  flagQuizNight: boolean = false;

  interfaceDoodleman = profileDoodleman;

  wakeupList = [
    '04:00 AM', '04:30 AM', '05:00 AM', '05:30 AM', '06:00 AM', 
    '06:30 AM', '07:00 AM', '07:30 AM', '08:00 AM', '08:30 AM', 
    '09:00 AM', '09:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM', '12:00 PM'
  ];

  bedtimeList = [
    '12:00 AM', '11:30 PM', '11:00 PM',
    '10:30 PM', '10:00 PM', '9:30 PM',
    '9:00 PM', '8:30 PM', '8:00 PM',
    '7:30 PM', '7:00 PM', '6:30 PM',
    '6:00 PM', '5:30 PM', '5:00 PM',
    '4:30 PM', '4:00 PM', '3:30 PM',
    '3:00 PM', '2:30 PM', '2:00 PM',
    '1:30 PM', '1:00 PM', '12:30 PM',
    '12:00 PM'
  ];


  feelingList = [
    'Super Tired', 'Pretty Tired', 'Feeling Okay', 'Feeling Good', 'Feeling Great'
  ];

  motivationList = [
    'This sucks', 'Another Day', 'Lets Roll!'
  ];

  quizMorningForm = this.formBuilder.group({
    morningWakeUp: ['', Validators.required],
    morningBedTime: ['', Validators.required],
    morningFeeling: ['', Validators.required],
    morningMotivation: ['', Validators.required],
    morningImportance: [''],
    morningMotivationalMessage: ['']
  });

  quizNightForm = this.formBuilder.group({
    nightDayScore: ['', Validators.required],
    nightProductivityFeeling: ['', Validators.required],
    nightTomorrowFeeling: ['', Validators.required],
    nightWakeUpTime: ['', Validators.required],
    nightDayRating: [0, Validators.required],
    nightImportance: [''],
    nightMotivationalMessage: ['']
  });

  nightDayScoreList = ['Not Great', 'Okay', 'Normal Day', 'Pretty Good!', 'What a great day!'];
  nightProductivityFeelingList = ['Not Productive', 'Through the motions', 'What a day!'];
  nightTomorrowFeelingList = ['Ugh...', 'Indifferent', 'Bring it on!'];
  nightDayRatingList = [1,2,3,4,5];



  ngOnInit(): void {
    this.userInterface = this.authService.currentUserSig();
    this.getDailyQuizzes();
    // Additional initialization logic here if necessary
  }

setScreenMorningQuiz() {
    this.setActiveQuizMorning = !this.setActiveQuizMorning;
  }

  setScreenNightQuiz() {
    this.setActiveQuizNight = !this.setActiveQuizNight;
  }

  triggerMorningQuizFlag() {
    this.flagQuizMorning = !this.flagQuizMorning;
  }

  triggerNightQuizFlag() {
    this.flagQuizNight = !this.flagQuizNight;
  }

  async submitQuiz(morningFlag: boolean) {
    const currentDate = this.today();
    
    if (morningFlag){
      const dailyQuizDocRef = doc(this.firestore, `user-daily-quizzes/${this.userInterface?.uid}/${currentDate}/morning-quiz`);
      const quizAnswers = {
        morningWakeUp: this.quizMorningForm.value.morningWakeUp,
        morningBedTime: this.quizMorningForm.value.morningBedTime,
        morningFeeling: this.quizMorningForm.value.morningFeeling,
        morningMotivation: this.quizMorningForm.value.morningMotivation,
        morningImportance: this.quizMorningForm.value.morningImportance,
        morningMotivationalMessage: this.quizMorningForm.value.morningMotivationalMessage || ''
      };

      await setDoc(dailyQuizDocRef, quizAnswers);
      const userScoreMasterDocRef = doc(this.firestore, `user-score-master/${this.userInterface?.uid}`);
      const userScoreSnap = await getDoc(userScoreMasterDocRef);
      if (userScoreSnap.exists()) {
        const points = userScoreSnap.data()['points'] || 0;
        await updateDoc(userScoreMasterDocRef, { points: points + 2 });
      } else {
        await updateDoc(userScoreMasterDocRef, { points: 2 });
      } 
      this.setScreenMorningQuiz();
      this.loadingMorningQuiz = true;
      this.ngOnInit();
    } else {
      const dailyQuizDocRef = doc(this.firestore, `user-daily-quizzes/${this.userInterface?.uid}/${currentDate}/night-quiz`);
      const quizAnswers = {
        nightDayScore: this.quizNightForm.value.nightDayScore,
        nightProductivityFeeling: this.quizNightForm.value.nightProductivityFeeling,
        nightTomorrowFeeling: this.quizNightForm.value.nightTomorrowFeeling,
        nightWakeUpTime: this.quizNightForm.value.nightWakeUpTime,
        nightDayRating: this.quizNightForm.value.nightDayRating,
        nightMotivationalMessage: this.quizNightForm.value.nightMotivationalMessage || ''
      };

      await setDoc(dailyQuizDocRef, quizAnswers);
      const userScoreMasterDocRef = doc(this.firestore, `user-score-master/${this.userInterface?.uid}`);
      const userScoreSnap = await getDoc(userScoreMasterDocRef);
      if (userScoreSnap.exists()) {
        const points = userScoreSnap.data()['points'] || 0;
        await updateDoc(userScoreMasterDocRef, { points: points + 2 });
      } else {
        await updateDoc(userScoreMasterDocRef, { points: 2 });
      } 
      this.setScreenNightQuiz();
      this.loadingNightQuiz = true;
      this.ngOnInit();

    }
    
  
  }

  async getDailyQuizzes() {
    const currentDate = this.today();
    const dailyQuizMorningDocRef = doc(this.firestore, `user-daily-quizzes/${this.userInterface?.uid}/${currentDate}/morning-quiz`);
    const dailyQuizMorningDocSnapshot = await getDoc(dailyQuizMorningDocRef);

    const dailyQuizNightDocRef = doc(this.firestore, `user-daily-quizzes/${this.userInterface?.uid}/${currentDate}/night-quiz`);
    const dailyQuizNightDocSnapshot = await getDoc(dailyQuizNightDocRef);

    if (dailyQuizMorningDocSnapshot.exists()){
      this.triggerMorningQuizFlag()
      this.loadingMorningQuiz = false;
      this.flagQuizMorning = true;
    } else {
      this.loadingMorningQuiz = false;
      this.flagQuizMorning = false;
    }

    if (dailyQuizNightDocSnapshot.exists()){
      this.triggerNightQuizFlag()
      this.loadingNightQuiz = false;
      this.flagQuizNight = true;
    } else {
      this.loadingNightQuiz = false;
      this.flagQuizNight = false;
    }

  }

  mapOptionToIndex(option: string, list: string[]): number {
    const index = list.indexOf(option);
    return index + 1;
  }
  

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
