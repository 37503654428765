import { Injectable, inject } from '@angular/core';
import { Firestore, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { UserInterface } from '../models/user.interface';
import { UserClassificationService } from './user-classification.service';
import { UserTitle, defaultUserTitle } from '../models/userTitle.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  firestore = inject(Firestore);
  authService = inject(AuthService);
  userClassificationService = inject(UserClassificationService);
  userInterface: UserInterface | null | undefined;

  constructor() {
    this.userInterface = this.authService.currentUserSig();
  }

  getUserInterface(): UserInterface | null | undefined {
    return this.userInterface;
  }

  classifyUser(profile: UserInterface): UserTitle {
    const userTitle = this.userClassificationService.userClassification(profile);
    return userTitle || defaultUserTitle;
  }
}

