import { Component } from '@angular/core';
import { AvatarMysteryBoxComponent } from '../../components/avatar-mystery-box/avatar-mystery-box.component';
import { CountdownClockComponent } from '../../components/countdown-clock/countdown-clock.component';

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [AvatarMysteryBoxComponent, CountdownClockComponent],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent {
  

}
