<div class="filter-module">
    <div class="filter-option" 
         (click)="selectTimeRange(7)" 
         [ngClass]="{'active': filterTimeRange === 7}">
      1W
    </div>
    <div class="filter-option" 
         (click)="selectTimeRange(14)" 
         [ngClass]="{'active': filterTimeRange === 14}">
      2W
    </div>
    <div class="filter-option" 
         (click)="selectTimeRange(21)" 
         [ngClass]="{'active': filterTimeRange === 21}">
      3W
    </div>
    <div class="filter-option" 
         (click)="selectTimeRange(30)" 
         [ngClass]="{'active': filterTimeRange === 30}">
      1M
    </div>        
  </div>
  
  <p class="stats-subtitle" style="text-align: center; font-weight: bold;">{{ formatDateWithSuffix(sevenDaysAgo.toISOString().split('T')[0]) }} - {{ formatDateWithSuffix(currentDate.toISOString().split('T')[0]) }}</p>
  
  @if (!dataReady) {
    <div class="time-series-loading-container" style="width: 365px; height: 300px; border: 3px solid var(--brand-color); border-radius: 16px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <mat-spinner />
    </div>
    <div class="pie-chart-loading-container" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between; margin-top: 10px; gap: 8px;">
      <div class="sub-pie-chart-loading-container" style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 165px; height: 175px; padding: 8px; border: 2px solid var(--brand-color); border-radius: 16px;">
        <mat-spinner />
      </div>
      <div class="sub-pie-chart-loading-container" style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 165px; height: 175px; padding: 8px; border: 2px solid var(--brand-color); border-radius: 16px;">
        <mat-spinner />
      </div>
    </div>
    
  } @else {
    <app-daily-quiz-timeseries [userQuizStats]="userQuizStats"></app-daily-quiz-timeseries>

    <app-daily-quiz-pie [userQuizStats]="userQuizStats"></app-daily-quiz-pie>
    
    
  }


  
  
