import { Component } from '@angular/core';
import { UserChatInterfaceComponent } from '../../components/user-chat-interface/user-chat-interface.component';

@Component({
  selector: 'app-community-page',
  standalone: true,
  imports: [UserChatInterfaceComponent],
  templateUrl: './community-page.component.html',
  styleUrl: './community-page.component.scss'
})
export class CommunityPageComponent {
  

}
