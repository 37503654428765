import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { UserAuthComponent } from './components/user-auth/user-auth.component';
import { TopToolbarComponent } from './shared/top-toolbar/top-toolbar.component';
import { BottomNavigationBarComponent } from './shared/bottom-navigation-bar/bottom-navigation-bar.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; // Add this for the spinner

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    RouterModule,
    LandingPageComponent,
    UserAuthComponent,
    TopToolbarComponent,
    BottomNavigationBarComponent,
    MatProgressSpinnerModule // Include Angular Material Spinner
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'werkit-dev';
  authService = inject(AuthService);
  router = inject(Router);

  isLoading = true; // Loading state

  isActive(route: string): boolean {
    return this.router.url === route;
  }
  isLandingPage(): boolean {
    return this.router.url === '/'; // Adjust the route path as needed
  }

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      if (user) {
        this.authService.loadUserInterface(user.uid).then(profile => {
          if (profile) {
            this.authService.currentUserSig.set(profile);
            this.router.navigateByUrl('/home');
          }
        }).catch(error => {
          console.error('Failed to load user profile:', error);
        }).finally(() => {
          this.isLoading = false; // Hide the spinner once done
        });
      } else {
        this.authService.currentUserSig.set(null);
        this.router.navigateByUrl('/');
        this.isLoading = false; // Hide the spinner if user is not logged in
      }
    });
  }
}


