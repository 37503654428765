
<div class="col" style="gap: 0px; ">
    <span class="progress-title">Daily Progress Bar</span> 

        @if (this.UserDailyStats.winPercentChallenges <= .50){
            @if (helperRoundingFunction((this.UserDailyStats.totalChallengesAvailable / 2) - this.UserDailyStats.totalChallengesCompleted) === 1){
                <p style="top: 0;">{{helperRoundingFunction((this.UserDailyStats.totalChallengesAvailable / 2) - this.UserDailyStats.totalChallengesCompleted)}} more challenge to win the day!</p>
            } @else {
                <p style="top: 0;">{{helperRoundingFunction((this.UserDailyStats.totalChallengesAvailable / 2) - this.UserDailyStats.totalChallengesCompleted)}} more challenges to win the day!</p>
            }
            
        } @else {
            <p style="top: 0;">You won the day! Finish strong!</p>
        }
             
</div>


<div class="progress-container">
    <div class="progress-bar" [ngStyle]="{ 'width': this.helperRoundingFunction(this.UserDailyStats.winPercentChallenges * 100) + '%','background-color': this.UserDailyStats.winPercentPoints >= 0.5 ? 'lightgreen' : 'lightcoral' }"></div>
    <div class="winners-line"></div>
</div>
  


  

  