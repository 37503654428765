@if (userInterface?.username === ''){
  <div class="page">
    <h1>Create your profile!</h1>
  </div>
} @else {
  <div class="page">  
    @if (username === userInterface?.username || !username) {
        
          <h1>Self Profile</h1>
          <!-- Self profile content here --><app-user-title [avatarInterface]="this.profileInterface" [currentUserProfile]="true" [onProfilePage]="true"></app-user-title> 
          <app-challenge-input-module />
        
      } @else {
        <div class="other-profile">
          <h1>Other User's Profile</h1>
          <app-user-title [avatarInterface]="this.profileInterface" [currentUserProfile]="false" [onProfilePage]="true"></app-user-title> 
        </div>
      }
    </div>  
}

  