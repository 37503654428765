<div style="display: flex; justify-content: center;">
    <span style="font-size: 20px; color: var(--text-color); border-bottom: 2px solid var(--brand-color);">Daily Activities</span>
</div>

<div class="col">
    <div class="action-cell" [routerLink]="['/quiz']">
        @if (isLoading){
            <div class="col">
                <mat-spinner />
            </div>
        } @else {
        <div class="action-header">
            <strong class="action-title">Daily Quiz</strong>
            <strong class="action-subtitle">Tap here to submit your daily quizzes!</strong>
            <div class="trophy-display">
                <div class="trophy-stack">
                    <mat-icon [ngStyle]="{'color': flagMorningQuiz ? '#D3AF37' : 'gray','opacity': flagMorningQuiz ? '1' : '0.25'}">light_mode</mat-icon>
                    <strong [ngStyle]="{'color': flagMorningQuiz ? '#D3AF37' : 'gray','opacity': flagMorningQuiz ? '1' : '0.25'}">Morning Quiz</strong>
                </div>
                <div class="trophy-stack">
                    <mat-icon [ngStyle]="{'color': flagNightQuiz ? '#D8D8D8;' : 'gray','opacity': flagNightQuiz ? '1' : '0.25'}">dark_mode</mat-icon>
                    <strong [ngStyle]="{'color': flagNightQuiz ? '#D8D8D8;' : 'gray','opacity': flagNightQuiz ? '1' : '0.25'}">Night Quiz</strong>
                </div>
            </div>
                
        </div>
    }
    </div>

    <div class="action-cell" [routerLink]="['/user-personal-challenges']">
        @if (isLoading){
            <div class="col">
                <mat-spinner />
            </div>
        } @else {
            <div class="action-header">
            <strong class="action-title">Personal Challenges</strong>
            <strong class="action-subtitle">Tap here to complete your daily challenges!</strong>
        </div>

        <div class="trophy-display">
            <div class="trophy-stack">
                <mat-icon [ngStyle]="{'color': flagPersonalEasy ? '#CD7F32' : 'gray', 'opacity': flagPersonalEasy ? '1' : '0.25'}">emoji_events</mat-icon>    
                <strong [ngStyle]="{'color': flagPersonalEasy ? '#CD7F32' : 'gray', 'opacity': flagPersonalEasy ? '1' : '0.25'}">Easy</strong>
            </div>
            <div class="trophy-stack">
                <mat-icon [ngStyle]="{'color': flagPersonalMedium ? '#D8D8D8' : 'gray', 'opacity': flagPersonalMedium ? '1' : '0.25'}">emoji_events</mat-icon>    
                <strong [ngStyle]="{'color': flagPersonalMedium ?  '#D8D8D8' : 'gray', 'opacity': flagPersonalMedium ? '1' : '0.25'}">Medium</strong>
            </div>
            <div class="trophy-stack">
                <mat-icon [ngStyle]="{'color': flagPersonalHard ? '#D3AF37' : 'gray', 'opacity': flagPersonalHard ? '1' : '0.25'}">emoji_events</mat-icon>    
                <strong [ngStyle]="{'color': flagPersonalHard ? '#D3AF37' : 'gray', 'opacity': flagPersonalHard ? '1' : '0.25'}">Hard</strong>
            </div>
        </div>   
        }
        
    </div>

    <div class="action-cell" [routerLink]="['/user-community-challenges']">
        @if (isLoading){
            <div class="col">
                <mat-spinner />
            </div>
        } @else {
            <div class="action-header">
                <strong class="action-title">Community Challenges</strong>
                <strong class="action-subtitle">Tap here to complete the community challenges!</strong>
            </div>
    
            <div class="trophy-display" style="display: flex; flex-direction: row; align-items: center; justify-content: space-evenly; width: 100%; margin-top: 15px;">
                <div class="trophy-stack" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <mat-icon [ngStyle]="{'color': flagCommunityEasyOne ? '#CD7F32' : 'gray', 'opacity': flagCommunityEasyOne ? '1' : '0.25'}">emoji_events</mat-icon>    
                    <strong [ngStyle]="{'color': flagCommunityEasyOne ? '#CD7F32' : 'gray', 'opacity': flagCommunityEasyOne ? '1' : '0.25'}">Easy</strong>
                </div>
                <div class="trophy-stack" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <mat-icon [ngStyle]="{'color': flagCommunityEasyTwo ? '#CD7F32' : 'gray', 'opacity': flagCommunityEasyTwo ? '1' : '0.25'}">emoji_events</mat-icon>    
                    <strong [ngStyle]="{'color': flagCommunityEasyTwo ? '#CD7F32' : 'gray', 'opacity': flagCommunityEasyTwo ? '1' : '0.25'}">Easy</strong>
                </div>
                <div class="trophy-stack" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <mat-icon [ngStyle]="{'color': flagCommunityMediumOne ? '#D8D8D8' : 'gray', 'opacity': flagCommunityMediumOne ? '1' : '0.25'}">emoji_events</mat-icon>    
                    <strong [ngStyle]="{'color': flagCommunityMediumOne ? '#D8D8D8' : 'gray', 'opacity': flagCommunityMediumOne ? '1' : '0.25'}">Medium</strong>
                </div>
                <div class="trophy-stack" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <mat-icon [ngStyle]="{'color': flagCommunityMediumTwo ? '#D8D8D8' : 'gray', 'opacity': flagCommunityMediumTwo ? '1' : '0.25'}">emoji_events</mat-icon>    
                    <strong [ngStyle]="{'color': flagCommunityMediumTwo ? '#D8D8D8' : 'gray', 'opacity': flagCommunityMediumTwo ? '1' : '0.25'}">Medium</strong>
                </div>
                
                <div class="trophy-stack" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <mat-icon [ngStyle]="{'color': flagCommunityHardOne ? '#D3AF37' : 'gray', 'opacity': flagCommunityHardOne ? '1' : '0.25'}">emoji_events</mat-icon>    
                    <strong [ngStyle]="{'color': flagCommunityHardOne ? '#D3AF37' : 'gray', 'opacity': flagCommunityHardOne ? '1' : '0.25'}">Hard</strong>
                </div>
                <div class="trophy-stack" style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
                    <mat-icon [ngStyle]="{'color': flagCommunityHardTwo ? '#D3AF37' : 'gray', 'opacity': flagCommunityHardTwo ? '1' : '0.25'}">emoji_events</mat-icon>    
                    <strong [ngStyle]="{'color': flagCommunityHardTwo ? '#D3AF37' : 'gray', 'opacity': flagCommunityHardTwo ? '1' : '0.25'}">Hard</strong>
                </div>
            </div>
        }
    </div>
    
</div>


