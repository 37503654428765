<mat-card class="mystery-box-card" [ngClass]="{ 'open': isBoxOpen }">
  <h2>Avatar Mystery Box</h2>
    <div class="grid">
      <h1>?</h1>
      <h1>?</h1>
      <h1>?</h1>
      <h1>?</h1>
      <div class="center-content">
        <h1 *ngIf="!selectedAvatar">?</h1>
        <img *ngIf="selectedAvatar" [src]="selectedAvatar" alt="An epic avatar" class="avatar-img" />
      </div>
      <h1>?</h1>
      <h1>?</h1>
      <h1>?</h1>
      <h1>?</h1>
    </div>
  </mat-card>
  
  @if (!this.mysteryBoxSpin){
    <button mat-flat-button color="primary" (click)="mysteryBoxEngine()">Feeling Lucky?</button>
  } @else {
    <button mat-flat-button color="primary" (click)="mysteryBoxEngine()" disabled="true">Feeling Lucky?</button>
  }
  
  