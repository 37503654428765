import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UserTitle } from '../../../../models/userTitle.model';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { UserInterface } from '../../../../models/user.interface';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-user-modal',
  standalone: true,
  imports: [MatCardModule, MatIconModule, CommonModule, UserModalComponent, MatButtonModule, RouterModule],
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.css']
})
export class UserModalComponent {
  @Input() profileInterface?: UserInterface;
  @Input() currentUserProfile?: boolean;
  @Input() onProfilePage?: boolean;
  
  @Output() closeModal = new EventEmitter<void>();

  



  close() {
    this.closeModal.emit();
  }
}
