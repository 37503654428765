@if (!this.tutorialStepFlag) {
  
  <div class="col" style="margin-top: 20px;">
    <app-user-title [avatarInterface]="interfaceDoodleman"></app-user-title>
    <div class="dialog-box" style="height: 200px; width: 300px; border: 3px solid var(--border-color); border-radius: 16px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
      <p>Welcome, Welcome, Welcome!</p>
      <p>Are you ready for August Camp?!</p>
      <p>We're coming for it all this year!</p>
      <p>Let's go! Don't stop!</p>
    </div>
    
    <form [formGroup]="profileForm" (ngSubmit)="submitProfileChanges()" class="col">
      <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput formControlName="profileUsername">
        <mat-error *ngIf="profileForm.get('profileUsername')?.hasError('required')">Username is required</mat-error>
        <mat-error *ngIf="profileForm.get('profileUsername')?.hasError('maxlength')">Username cannot exceed 12 characters</mat-error>
        <mat-error *ngIf="profileForm.get('profileUsername')?.hasError('invalidUsername')">Username can only contain letters and numbers</mat-error>
        <mat-error *ngIf="profileForm.get('profileUsername')?.hasError('profanityDetected')">Username contains prohibited language</mat-error>
      </mat-form-field>
    
      <mat-form-field appearance="fill" style="margin-top: 10px;">
        <mat-label>Country</mat-label>
        <mat-select formControlName="profileCountry">
          <mat-option *ngFor="let country of countryList" [value]="country">{{country}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileForm.get('profileCountry')?.hasError('required')">Country is required</mat-error>
      </mat-form-field>
    
      <mat-form-field appearance="fill">
        <mat-label>Birth Month</mat-label>
        <mat-select formControlName="profileMonth" (selectionChange)="updateBirthstone($event.value)">
          <mat-option *ngFor="let month of monthsList" [value]="month">{{month}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileForm.get('profileMonth')?.hasError('required')">Birth month is required</mat-error>
      </mat-form-field>
    
      <mat-form-field appearance="fill">
        <mat-label>Birthstone</mat-label>
        <input matInput formControlName="profileBirthstone" readonly>
      </mat-form-field>
    
      <mat-form-field appearance="fill">
        <mat-label>Profession</mat-label>
        <mat-select formControlName="profileWerk">
          <mat-option *ngFor="let werk of werkList" [value]="werk">{{werk}}</mat-option>
        </mat-select>
      </mat-form-field>
    
      <mat-form-field appearance="fill">
        <mat-label>Bio</mat-label>
        <textarea matInput formControlName="profileBio"></textarea>
        <mat-error *ngIf="profileForm.get('profileBio')?.hasError('maxlength')">Bio cannot exceed 140 characters</mat-error>
      </mat-form-field>
    
      <button mat-raised-button color="primary" type="submit">Save Changes</button>
    </form>
  </div>
  
} @else {
  
  <div class="col">
    <app-avatar-mystery-box (avatarSelected)="onAvatarSelected($event)"></app-avatar-mystery-box>
    @if (this.flagAvatarSelected){
      <button mat-raised-button color="primary" (click)="forcePageReload()"> Ready for a challenge? </button>
    }
  </div>
}

  
  