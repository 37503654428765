import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeUtilsService {

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
  timeToMinutes(timeStr: string): number {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours !== 12) hours += 12;
    if (modifier === 'AM' && hours === 12) hours = 0;
    return hours * 60 + minutes;
  }

  minutesToTimeStr(minutes: number): string {
    const hours = Math.floor(minutes / 60) % 24;
    const mins = Math.floor(minutes % 60);
    const am_pm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${mins.toString().padStart(2, '0')} ${am_pm}`;
  }

  minutesToDurationStr(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${hours} hours and ${mins} minutes`;
  }

  calculateAverage(timeArray: number[]): number {
    const validTimes = timeArray.filter(time => !isNaN(time));
    if (validTimes.length === 0) return NaN;
    const sum = validTimes.reduce((acc, time) => acc + time, 0);
    return sum / validTimes.length;
  }

  getDaysInRange(startDate: Date, endDate: Date): Date[] {
    const days = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
  }

  getDaysAgo(date: Date, daysAgo: number): Date {
    const pastDate = new Date(date);
    pastDate.setDate(pastDate.getDate() - daysAgo + 1);
    return pastDate;
  }
}
