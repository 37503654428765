import { Injectable, inject } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { UserInterface } from '../../../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class QuizDataService {
  firestore = inject(Firestore);

  async getUserQuizzes(userInterface: UserInterface, daysInRange: Date[]): Promise<any[]> {
    const quizData = [];

    for (const day of daysInRange) {
      const formattedDate = this.formatDate(day);
      const docRefUserMorningQuizzes = doc(this.firestore, `user-daily-quizzes/${userInterface.uid}/${formattedDate}/morning-quiz`);
      const docSnapUserMorningQuizzes = await getDoc(docRefUserMorningQuizzes);

      if (docSnapUserMorningQuizzes.exists()) {
        const data = docSnapUserMorningQuizzes.data();
        quizData.push({
          date: formattedDate,
          morningWakeUp: data['morningWakeUp'] || 'N/A',
          morningBedTime: data['morningBedTime'] || 'N/A',
          morningFeeling: data['morningFeeling'] || 'N/A',
          morningMotivation: data['morningMotivation'] || 'N/A'
        });
      } else {
        quizData.push({
          date: formattedDate,
          morningWakeUp: 'N/A',
          morningBedTime: 'N/A',
          morningFeeling:  'N/A',
          morningMotivation: 'N/A'
        });
      }
    }

    return quizData;
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}
