<div class="modal">
  <div class="modal-content">
    <span class="close-button" (click)="close()">&times;</span>
    <div class="profile-section">
      
      <img class="avatar" [src]="profileInterface?.userAvatarURL" alt="Avatar" height="50px">
      <div class="user-details">
        <div class="username">{{this.profileInterface?.username}}</div>
        <!-- <div class="rank">{{this.profileInterface?.userRank}}</div> -->
      </div>
    </div>
    <table class="info-table">
      <tr>
        <td><mat-icon>star</mat-icon></td>
        <td>Current Level:</td>
        <td>{{this.profileInterface?.userLevel}}</td>
      </tr>
      <tr>
        <td><mat-icon>cake</mat-icon></td>
        <td>Birthstone:</td>
        <td>{{this.profileInterface?.userBirthstone}}</td>
      </tr>
      <tr>
        <td><mat-icon>flag</mat-icon></td>
        <td>Country:</td>
        <td>{{this.profileInterface?.userCountry}}</td>
      </tr>
      <tr>
        <td><mat-icon>work</mat-icon></td>
        <td>Werk:</td>
        <td>{{this.profileInterface?.userWerk}}</td>
      </tr>
      <tr>
        <td><mat-icon>info</mat-icon></td>
        <td>Bio:</td>
        <td></td>
      </tr>
    </table>
    <div class="bio-box" style=" height: 100%; width: 90%;">
      <p>{{this.profileInterface?.userBio}}</p>
    </div>
    @if (!onProfilePage){
      @if (!currentUserProfile) {
        <button mat-raised-button color="primary" [routerLink]="['/profile', profileInterface?.username]">Visit Profile!</button>
      }
    }
     
  </div>
</div>
