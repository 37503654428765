// src/app/global.service.ts
import { Injectable } from '@angular/core';

import { UserTitle } from '../models/userTitle.model';
import { UserInterface } from '../models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserClassificationService {


  userClassification(userProfile: UserInterface | null | undefined): UserTitle | undefined {
    if (!userProfile) {
      console.error("Woah? How'd you call this?!");
      return undefined;
    }
    
    const avatarFlagURL = this.countryClassification(userProfile.userCountry);
    const [avatarRank, avatarCrownColor, avatarCrownURL, avatarNextRank, avatarNextCrownURL, avatarNextCrownColor, avatarNextLevelReq] = this.rankClassification(userProfile.userRole, userProfile.userLevel);

    const temporaryUserTitle: UserTitle = {
      avatarURL: userProfile.userAvatarURL,
      avatarUsername: userProfile.username || '',
      avatarCountry: userProfile.userCountry,
      avatarFlagURL: avatarFlagURL,
      avatarLevel: userProfile.userLevel,
      avatarExperience: userProfile.userExperience,
      avatarStreak: userProfile.userStreak,
      avatarCrownURL: avatarCrownURL,
      avatarCrownColor: avatarCrownColor,
      avatarRank: avatarRank, // Type assertion to ensure correct type
      avatarNextRank: avatarNextRank,
      avatarNextCrownURL: avatarNextCrownURL,
      avatarNextCrownColor: avatarNextCrownColor,
      avatarNextLevelReq: avatarNextLevelReq
    };

    return temporaryUserTitle;
  }

  countryClassification(userCountry: string): string {
    let userFlag: string = '';

    if (userCountry === 'America') {
      userFlag = `/assets/flags/flagAmerican.png`
    } else if (userCountry === 'Canada'){
      userFlag = `/assets/flags/flagCanada.png`
    } else if (userCountry === 'Mexico'){
      userFlag = `/assets/flags/flagMexico.png`
    } else if (userCountry === 'Ireland'){
      userFlag = `/assets/flags/flagIreland.png`
    } else if (userCountry === 'Pakistan'){
      userFlag = `/assets/flags/flagPakistan.png`
    } else if (userCountry === 'Australia'){
      userFlag = `/assets/flags/flagAustralia.png`
    } else {
      userFlag = `/assets/flags/flagAmerican.png`
    }

    return userFlag;
  }

  rankClassification(avatarRole: 'user' | 'mod' | 'admin', avatarLevel: number): [UserTitle['avatarRank'], string, string, UserTitle['avatarRank'], string, string, number] {
    let avatarRank: UserTitle['avatarRank'] = '';
    let avatarCrownColor: string = '';
    let avatarCrownURL: string = '';
    let avatarNextRank: UserTitle['avatarRank'] = '';
    let avatarNextCrownURL: string = '';
    let avatarNextCrownColor: string = '';
    let avatarNextLevelReq: number = 0;

    if (avatarRole === 'user') {
        if (avatarLevel <= 7) {
            avatarRank = 'Robot';
            avatarCrownColor = '';
            avatarCrownURL = '';

            avatarNextRank = 'Noobie';
            avatarNextCrownURL = '/assets/crowns/crownNoobie.png';
            avatarNextCrownColor = '#FF9AFB';
            avatarNextLevelReq = 7;

        } else if (avatarLevel <= 14) {
            avatarRank = 'Noobie';
            avatarCrownColor = '#FF9AFB';
            avatarCrownURL = '/assets/crowns/crownNoobie.png';

            avatarNextRank = 'Initiate';
            avatarNextCrownURL = '/assets/crowns/crownInitiate.png';
            avatarNextCrownColor = '#F59300';
            avatarNextLevelReq = 14;

        } else if (avatarLevel <= 28) {
            avatarRank = 'Initiate';
            avatarCrownColor = '#F59300';
            avatarCrownURL = '/assets/crowns/crownInitiate.png';

            avatarNextRank = 'Member';
            avatarNextCrownURL = '/assets/crowns/crownMember.png';
            avatarNextCrownColor = '#27FF04';
            avatarNextLevelReq = 28;

        } else if (avatarLevel <= 56) {
            avatarRank = 'Member';
            avatarCrownColor = '#27FF04';
            avatarCrownURL = '/assets/crowns/crownMember.png';

            avatarNextRank = 'Leader';
            avatarNextCrownURL = '/assets/crowns/crownLeader.png';
            avatarNextCrownColor = '#00C2FF';
            avatarNextLevelReq = 56;

        } else if (avatarLevel <= 90) {
            avatarRank = 'Leader';
            avatarCrownColor = '#00C2FF';
            avatarCrownURL = '/assets/crowns/crownLeader.png'; 

            avatarNextRank = 'General';
            avatarNextCrownURL = '/assets/crowns/crownGeneral.png';
            avatarNextCrownColor = '#7622FF';
            avatarNextLevelReq = 90;

        } else if (avatarLevel <= 140) {
            avatarRank = 'General';
            avatarCrownColor = '#7622FF';
            avatarCrownURL = '/assets/crowns/crownGeneral.png';

            avatarNextRank = 'Competitor';
            avatarNextCrownURL = '/assets/crowns/crownCompetitor.png';
            avatarNextCrownColor = '#C3C3C3';
            avatarNextLevelReq = 140;

        } else if (avatarLevel <= 224) {
            avatarRank = 'Competitor';
            avatarCrownColor = '#C3C3C3';
            avatarCrownURL = '/assets/crowns/crownCompetitor.png';

            avatarNextRank = 'Challenger';
            avatarNextCrownURL = '/assets/crowns/crownChallenger.png';
            avatarNextCrownColor = '#FFD600';
            avatarNextLevelReq = 224;

        } else if (avatarLevel <= 308) {
            avatarRank = 'Challenger';
            avatarCrownColor = '#FFD600';
            avatarCrownURL = '/assets/crowns/crownChallenger.png';

            avatarNextRank = 'Champion';
            avatarNextCrownURL = '/assets/crowns/crownChampion.png';
            avatarNextCrownColor = '#FD0001';
            avatarNextLevelReq = 308;
        } else {
            avatarRank = 'Champion';
            avatarCrownColor = '#FD0001';
            avatarCrownURL = '/assets/crowns/crownChampion.png';

            avatarNextRank = '';
            avatarNextCrownURL = '';
            avatarNextCrownColor = '';
            avatarNextLevelReq = 0;
        }
    } else if (avatarRole === 'mod') {
        avatarRank = 'Moderator';
        avatarCrownColor = '#7A97AC';
        avatarCrownURL = '/assets/crowns/crownMod.png';
        
        avatarNextRank = '';
        avatarNextCrownURL = '';
        avatarNextCrownColor = '';
        avatarNextLevelReq = 0;
    } else if (avatarRole === 'admin') {
        avatarRank = 'Administrator';
        avatarCrownColor = '#FFEB3B';
        avatarCrownURL = '/assets/crowns/crownAdmin.png';
        
        avatarNextRank = '';
        avatarNextCrownURL = '';
        avatarNextCrownColor = '';
        avatarNextLevelReq = 0;
    }

    return [avatarRank, avatarCrownColor, avatarCrownURL, avatarNextRank, avatarNextCrownURL, avatarNextCrownColor, avatarNextLevelReq];
}


  constructor() { }
}
