import { Component, Input, OnInit, inject } from '@angular/core';
import { doc, getDoc } from 'firebase/firestore';
import { Firestore } from '@angular/fire/firestore';
import { UserInterface } from '../../models/user.interface';
import { DailyQuizTimeseriesComponent } from './subcomponents/daily-quiz-timeseries/daily-quiz-timeseries.component';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';

@Component({
  selector: 'app-daily-quiz-stat-display',
  standalone: true,
  imports: [DailyQuizTimeseriesComponent, CommonModule, SharedMaterialModule],
  templateUrl: './daily-quiz-stat-display.component.html',
  styleUrl: './daily-quiz-stat-display.component.scss'
})
export class DailyQuizStatDisplayComponent implements OnInit {
  firestore = inject(Firestore);
  @Input() userInterface: UserInterface | null | undefined;

  userQuizStats: {
    quizDates: string[],
    morningWakeUpTimes: string[],
    morningBedTimes: string[],
    bedTimeMinutes: number[],
    wakeUpMinutes: number[],
    sleepTimeMinutes: number[],
    awakeTimeMinutes: number[],
    avgBedTimeMinutes?: number,
    avgWakeUpTimeMinutes?: number,
    avgSleepTimeMinutes?: number,
    avgAwakeTimeMinutes?: number,
  } = {
    quizDates: [],
    morningWakeUpTimes: [],
    morningBedTimes: [],
    bedTimeMinutes: [],
    wakeUpMinutes: [],
    sleepTimeMinutes: [],
    awakeTimeMinutes: []
  };

  dataReady = false; // This flag indicates whether data is ready
  
  ngOnInit(): void {
    this.getUserQuizzes();
  }

  async getUserQuizzes() {
    if (!this.userInterface) return;
    
    const currentDate = new Date();
    const sevenDaysAgo = this.getDaysAgo(currentDate, 7);
    const daysInRange = this.getDaysInRange(sevenDaysAgo, currentDate);

    for (const day of daysInRange) {
      const formattedDate = this.formatDate(day);
      const docRefUserMorningQuizzes = doc(this.firestore, `user-daily-quizzes/${this.userInterface.uid}/${formattedDate}/morning-quiz`);
      
      const docSnapUserMorningQuizzes = await getDoc(docRefUserMorningQuizzes);

      if (docSnapUserMorningQuizzes.exists()) {
        const data = docSnapUserMorningQuizzes.data();
        this.userQuizStats.quizDates.push(formattedDate);
        this.userQuizStats.morningWakeUpTimes.push(data['morningWakeUp'] || 'N/A');
        this.userQuizStats.morningBedTimes.push(data['morningBedTime'] || 'N/A');
      } else {
        this.userQuizStats.quizDates.push(formattedDate);
        this.userQuizStats.morningWakeUpTimes.push('N/A');
        this.userQuizStats.morningBedTimes.push('N/A');
      }
    }

    this.processUserQuizStats();
    this.dataReady = true; // Set the flag to true when data is ready
  }

  calculateAverage(timeArray: number[]): number {
    const validTimes = timeArray.filter(time => !isNaN(time));
    if (validTimes.length === 0) return NaN;
    const sum = validTimes.reduce((acc, time) => acc + time, 0);
    return sum / validTimes.length;
  }

  processUserQuizStats() {
    const shiftedBedTimes = this.userQuizStats.morningBedTimes.slice(1).concat('N/A');
    this.userQuizStats.bedTimeMinutes = shiftedBedTimes.map(time => this.timeToMinutes(time));
    this.userQuizStats.wakeUpMinutes = this.userQuizStats.morningWakeUpTimes.map(time => this.timeToMinutes(time));
    this.userQuizStats.bedTimeMinutes = this.userQuizStats.bedTimeMinutes.map(minutes => minutes < 720 ? minutes + 1440 : minutes);
    this.userQuizStats.sleepTimeMinutes = this.userQuizStats.wakeUpMinutes.map((wakeUp, index) => wakeUp + (1440 - this.userQuizStats.bedTimeMinutes[index]));
    this.userQuizStats.awakeTimeMinutes = this.userQuizStats.sleepTimeMinutes.map(sleep => 1440 - sleep);

    this.userQuizStats.avgBedTimeMinutes = this.calculateAverage(this.userQuizStats.bedTimeMinutes);
    this.userQuizStats.avgWakeUpTimeMinutes = this.calculateAverage(this.userQuizStats.wakeUpMinutes);
    this.userQuizStats.avgSleepTimeMinutes = this.calculateAverage(this.userQuizStats.sleepTimeMinutes);
    this.userQuizStats.avgAwakeTimeMinutes = this.calculateAverage(this.userQuizStats.awakeTimeMinutes);

    console.log("Average Bed Time:", this.minutesToTimeStr(this.userQuizStats.avgBedTimeMinutes));
    console.log("Average Wake Up Time:", this.minutesToTimeStr(this.userQuizStats.avgWakeUpTimeMinutes));
    console.log("Average Sleep Time:", this.minutesToDurationStr(this.userQuizStats.avgSleepTimeMinutes));
    console.log("Average Awake Time:", this.minutesToDurationStr(this.userQuizStats.avgAwakeTimeMinutes));
  }

  timeToMinutes(timeStr: string): number {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  }

  minutesToTimeStr(minutes: number): string {
    const hours = Math.floor(minutes / 60) % 24;
    const mins = Math.floor(minutes % 60);
    const am_pm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${mins.toString().padStart(2, '0')} ${am_pm}`;
  }

  minutesToDurationStr(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = Math.floor(minutes % 60);
    return `${hours} hours and ${mins} minutes`;
  }

  getDaysInRange(startDate: Date, endDate: Date): Date[] {
    const days = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
  }

  getDaysAgo(date: Date, daysAgo: number): Date {
    const pastDate = new Date(date);
    pastDate.setDate(pastDate.getDate() - daysAgo + 1);
    return pastDate;
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

