import { Component, inject, OnInit } from '@angular/core';
import { Firestore, doc, setDoc, updateDoc, getDoc } from '@angular/fire/firestore';
import { ReactiveFormsModule, FormBuilder, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../models/user.interface';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { AvatarMysteryBoxComponent } from '../avatar-mystery-box/avatar-mystery-box.component';
import { RouterModule } from '@angular/router';
import { UserTitleComponent } from "../../shared/user-title/user-title.component";
import { profileDoodleman } from '../../models/avatars/doodleman.profile';
import BadWordsFilter from 'bad-words';

type Month = 'January' | 'February' | 'March' | 'April' | 'May' | 'June' | 'July' | 'August' | 'September' | 'October' | 'November' | 'December';
type Birthstone = '' | 'Garnet' | 'Amethyst' | 'Aquamarine' | 'Diamond' | 'Emerald' | 'Pearl' | 'Ruby' | 'Peridot' | 'Sapphire' | 'Opal' | 'Topaz' | 'Tanzanite' | 'Moonstone';
type Werk = '' | 'Other' | 'White Collar' | 'Blue Collar' | 'Creative' | 'Freelancer' | 'Outdoor Activities' | 'Healthcare' | 'Programming' | 'Education' | 'Service Industry' | 'Retail Industry' | 'Public Service' | 'Police Force' | 'Firefighter' | 'Student' | 'Retired';

@Component({
  selector: 'app-set-user-profile',
  standalone: true,
  imports: [ReactiveFormsModule, MatInputModule, MatCardModule, MatButtonModule, MatSelectModule, CommonModule, AvatarMysteryBoxComponent, RouterModule, UserTitleComponent],
  templateUrl: './set-user-profile.component.html',
  styleUrls: ['./set-user-profile.component.scss']
})
export class SetUserProfileComponent implements OnInit {
  userInterface: UserInterface | undefined | null;
  formBuilder = inject(FormBuilder);
  firestore = inject(Firestore);
  authService = inject(AuthService);
  badWordsFilter = new BadWordsFilter();
  interfaceDoodleman = profileDoodleman;
  flagAvatarSelected: boolean = false;

  tutorialStepFlag = false;

  setTutorialStepFlag() {
    this.tutorialStepFlag = !this.tutorialStepFlag;
  }

  countryList = ['America', 'Australia', 'Canada', 'Ireland', 'Mexico', 'Pakistan'];
  werkList = [
    'White Collar', 'Blue Collar', 'Creative', 'Freelancer', 'Outdoor Activities', 'Healthcare',
    'Programming', 'Education', 'Service Industry', 'Retail Industry', 'Public Service', 'Police Force',
    'Firefighter', 'Student', 'Retired', 'Other'
  ];

  monthsList: Month[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  birthstoneMapping: Record<Month, Birthstone> = {
    'January': 'Garnet',
    'February': 'Amethyst',
    'March': 'Aquamarine',
    'April': 'Diamond',
    'May': 'Emerald',
    'June': 'Moonstone',
    'July': 'Ruby',
    'August': 'Peridot',
    'September': 'Sapphire',
    'October': 'Opal',
    'November': 'Topaz',
    'December': 'Tanzanite'
  };

  profileForm = this.formBuilder.nonNullable.group({
    profileUsername: ['', [
      Validators.required, 
      Validators.maxLength(12), 
      this.usernameSanitizer(), 
      this.profanityFilter()
    ]],
    profileCountry: ['', Validators.required],
    profileMonth: ['', Validators.required],
    profileBirthstone: ['' as Birthstone],
    profileWerk: ['' as Werk],
    profileBio: ['', [Validators.maxLength(140)]],
    profileAvatar: ['']
  });
  

  // Custom validator to ensure the username contains only alphanumeric characters
  usernameSanitizer(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const isValid = /^[A-Za-z0-9]*$/.test(value);  // Only allow A-Z, a-z, 0-9
      return isValid ? null : { invalidUsername: true };
    };
  }

  profanityFilter(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value || '';
      const containsProfanity = this.badWordsFilter.isProfane(value);
      return containsProfanity ? { profanityDetected: true } : null;
    };
  }


  ngOnInit(): void {
    this.userInterface = this.authService.currentUserSig();
  }

  updateBirthstone(selectedMonth: Month) {
    const birthstone = this.birthstoneMapping[selectedMonth] || '';
    this.profileForm.patchValue({ profileBirthstone: birthstone });
  }

  async onAvatarSelected(avatar: string) {
    const selectedAvatar: string = avatar;
    this.flagAvatarSelected = !this.flagAvatarSelected;
  
    if (this.userInterface){
      const userDocRef = doc(this.firestore, `user-interfaces/${this.userInterface.uid}`);
      const userInventoryDocRef = doc(this.firestore, `user-inventory/${this.userInterface.uid}`)

      await updateDoc(userDocRef, { userAvatarURL: selectedAvatar });
      await updateDoc(userInventoryDocRef, { userAvatarURL: selectedAvatar });
    }
  
    console.log(this.profileForm.value.profileAvatar);
  }

  forcePageReload() {
    window.location.reload()
  }

  async submitProfileChanges() {
    if (this.profileForm.valid && this.userInterface) {
      
      // Check if the username is already taken
      const masterUsernameDocRef = doc(this.firestore, `master-usernames/${this.profileForm.value.profileUsername}`);
      const masterUsernameDocSnap = await getDoc(masterUsernameDocRef);

      if (masterUsernameDocSnap.exists()) {
        console.error('Username is already taken');
        return;
      }

      // Add the new username to the master-usernames collection
      await setDoc(masterUsernameDocRef, { uid: this.userInterface.uid });

      const updatedUserInterface: UserInterface = {
        ...this.userInterface,
        username: this.profileForm.value.profileUsername,
        userCountry: this.profileForm.value.profileCountry || '',
        userBirthstone: (this.profileForm.value.profileBirthstone as Birthstone) || '',
        userWerk: (this.profileForm.value.profileWerk as Werk) || 'Other',
        userBio: this.profileForm.value.profileBio || '',
      };

      const updatedFields = {
        username: this.profileForm.value.profileUsername,
        userCountry: this.profileForm.value.profileCountry || '',
        userBirthstone: (this.profileForm.value.profileBirthstone as Birthstone) || '',
        userWerk: (this.profileForm.value.profileWerk as Werk) || 'Other',
        userBio: this.profileForm.value.profileBio || '',
      };

      const userDocRef = doc(this.firestore, `user-interfaces/${this.userInterface.uid}`);
      await updateDoc(userDocRef, updatedFields);
      

      this.setTutorialStepFlag()

      this.authService.currentUserSig.set(updatedUserInterface);
    }
  }
}


