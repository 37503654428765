<div class="page">
    @if (!this.userInterface?.username){
        
        <app-set-user-profile/>
    } @else {
        
        <app-user-weekly-progress [userInterface]="this.userInterface" />
        <app-community-stats-module/>
        <app-user-daily-progress [userInterface]="this.userInterface" />

        <app-user-daily-actions [userInterface]="this.userInterface" />
       
        }
    
</div>


