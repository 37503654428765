import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedMaterialModule } from '../../../../shared/material-components/material-module.module';
import { SportEntry } from '../../user-fitness-tracker.models';

@Component({
  selector: 'app-sport-modal',
  standalone: true,
  imports: [SharedMaterialModule, ReactiveFormsModule],
  templateUrl: './sport-modal.component.html',
  styleUrl: './sport-modal.component.scss'
})
export class SportModalComponent implements OnInit{
  dialogRef = inject(MatDialogRef);


  close () {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    console.log("Combat Init...");
  }

  formBuilder = inject(FormBuilder);
  
  sportInputForm = this.formBuilder.group({
    sportName: [null, Validators.required],
    sportEvent: [null, Validators.required],
    sportTime: this.formBuilder.group({
      hours: [0, [Validators.required, Validators.min(0)]],
      minutes: [0, [Validators.required, Validators.min(0), Validators.max(59)]]
    }),
    sportLessons: [null, Validators.required],
    sportFocus: [null, Validators.required]
  });

  sportNameList: string [] = ['Basketball', 'Boxing', 'Football', 'Golf', 'Jiu-Jitzu', 'Judo', 'Kickboxing', 'Muay Thai', 'Pickleball', 'Soccer', 'Tennis', 'Wrestling'];
  sportEventList: string [] = ['Practice', 'Compeition', 'Free Play'];
  sportDurationHourList: number[] = Array.from({length: 10}, ( _, i) => i * 1) 
  sportDurationMinutesList: number[] = Array.from({length: 60}, (_, i) => (i+1) * 1);
  

  onSubmit() {
    if (this.sportInputForm.valid) {
      const formValue = this.sportInputForm.value;
  
      const sportName = formValue.sportName ?? ''; // default to empty string if null or undefined
      const sportEvent = formValue.sportEvent ?? '';
      const sportLessons = formValue.sportLessons ?? 0; // default to 0 if null or undefined
      const sportFocus = formValue.sportFocus ?? '';
      const hours = formValue.sportTime?.hours ?? 0;
      const minutes = formValue.sportTime?.minutes ?? 0;
      const totalMinutes = (hours * 60) + minutes;
  
      const submissionData: SportEntry = {
        sportName,
        sportEvent,
        sportTime: totalMinutes,
        sportLessons,
        sportFocus,
      };
  
      console.log('Sport Form Submitted', submissionData);
      this.dialogRef.close(submissionData);
    }
  }
  
}
