import { Component, inject, OnInit, Input } from '@angular/core';
import { Firestore, collection, query, getDocs } from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../models/user.interface';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';
import { UserForumProfileComponent } from '../user-forum-profile/user-forum-profile.component';
import { UserClassificationService } from '../../services/user-classification.service';
import { UserTitle } from '../../models/userTitle.model';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-user-weekly-progress',
  standalone: true,
  imports: [SharedMaterialModule, UserForumProfileComponent, CommonModule],
  templateUrl: './user-weekly-progress.component.html',
  styleUrl: './user-weekly-progress.component.scss'
})
export class UserWeeklyProgressComponent implements OnInit {
  @Input() userInterface: UserInterface | null | undefined;
  authService = inject(AuthService);
  fireStore = inject(Firestore);
  startDate: string = '';
  endDate: string = '';
  userForumTitle: UserTitle | null | undefined;
  isLoading: boolean = true;

  constructor(
    private userClassificationService: UserClassificationService
  ) {}

  

  UserWeeklyStats = {
    totalPointsEarned: 0,
    totalChallengesCompleted: 0,
    totalChallengesAvailable: 0,
    winPercent: 0
  };

  readonly defaultPersonalChallenges = 3;
  readonly defaultCommunityChallenges = 6;
  readonly totalDefaultChallengesPerDay = this.defaultPersonalChallenges + this.defaultCommunityChallenges;

  ngOnInit(): void {
    this.userForumTitle = this.userClassificationService.userClassification(this.userInterface);
    
    this.startDate = this.formatDate(this.getPreviousMonday(new Date()));
    this.endDate = this.formatDate(new Date());
    if (this.userInterface) {
        this.getUserWeeklyStats().then(() => {
            this.isLoading = false;  // Only set isLoading to false after data is fully loaded
        });
    } else {
        this.isLoading = false;  // Set to false if no user is authenticated
    }
}

  async getUserWeeklyStats() {
    const currentDate = new Date();
    const lastSundayDate = this.getPreviousMonday(currentDate);

    if (this.userInterface) {
      const daysInRange = this.getDaysInRange(lastSundayDate, currentDate);

      for (const day of daysInRange) {

        // Process personal challenges for the day
        await this.processChallengesForDay(`user-daily-challenges/${this.userInterface.uid}/daily-personal-challenges`, day, this.defaultPersonalChallenges);

        // Process community challenges for the day
        await this.processChallengesForDay(`user-daily-challenges/${this.userInterface.uid}/daily-community-challenges`, day, this.defaultCommunityChallenges);
      }

      this.UserWeeklyStats.winPercent = (this.UserWeeklyStats.totalChallengesCompleted / this.UserWeeklyStats.totalChallengesAvailable) * 100;
      
    } else {
      
    }
  }

  async processChallengesForDay(collectionPath: string, day: Date, defaultChallenges: number) {
    const challengesCollectionRef = collection(this.fireStore, collectionPath);
    const formattedDate = this.formatDate(day);
    const q = query(challengesCollectionRef);

    const querySnapshot = await getDocs(q);

    let dayChallengesAvailable = 0;
    let dayChallengesCompleted = 0;
    let dayPointsEarned = 0;

    querySnapshot.forEach((doc) => {
      if (doc.id === formattedDate) {
        const challenges = doc.data()['challenges'];

        challenges.forEach((challenge: any) => {
          dayChallengesAvailable++;
          if (challenge.challenge_status) {
            dayChallengesCompleted++;
            dayPointsEarned += this.getPointsByDifficulty(challenge.challenge_difficulty);
          }
        });
      }
    });

    // If no challenges were found for the day, assume the default count
    if (dayChallengesAvailable === 0) {
      dayChallengesAvailable = defaultChallenges;
    }

    // Update weekly stats
    this.UserWeeklyStats.totalChallengesAvailable += dayChallengesAvailable;
    this.UserWeeklyStats.totalChallengesCompleted += dayChallengesCompleted;
    this.UserWeeklyStats.totalPointsEarned += dayPointsEarned;
  }

  getDaysInRange(startDate: Date, endDate: Date): Date[] {
    const days = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      days.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return days;
  }

  getPreviousMonday(date: Date): Date {
    const previousMonday = new Date(date);
    const day = date.getDay();
    const diff = day === 1 ? 0 : (day === 0 ? 6 : day - 1);
    previousMonday.setDate(date.getDate() - diff);
    return previousMonday;
}


  getPointsByDifficulty(difficulty: string): number {
    switch (difficulty) {
      case 'easy': return 1;
      case 'medium': return 2;
      case 'hard': return 3;
      default: return 0;
    }
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  formatDateWithSuffix(dateStr: string): string {
    const [year, month, day] = dateStr.split('-').map(Number);
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[month - 1];
    const daySuffix = this.getDaySuffix(day);

    return `${monthName} ${day}${daySuffix}, ${year}`;
}

getDaySuffix(day: number): string {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}




  helperRoundingFunction(value: number){
    return Math.round(value);
  }
}


