<mat-toolbar class="bottom-nav">
    <button mat-icon-button [routerLink]="['/home']" [class.active]="isActive('/home')" aria-label="Home">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </button>

    <button mat-icon-button [routerLink]="['/fitness']" [class.active]="isActive('/fitness')" aria-label="fitness">
      <mat-icon>fitness_center</mat-icon>
      <span>Fitness</span>
    </button>

    <button mat-icon-button [routerLink]="['/statistics']" [class.active]="isActive('/statistics')" aria-label="Statistics">
      <mat-icon>bar_chart</mat-icon>
      <span>Statistics</span>
    </button>
    <button mat-icon-button [routerLink]="['/community']" [class.active]="isActive('/community')" aria-label="Community">
      <mat-icon>people</mat-icon>
      <span>Community</span>
    </button>
    <button mat-icon-button [routerLink]="['/profile']" [class.active]="isActive('/profile')" aria-label="Profile">
      <mat-icon>person</mat-icon>
      <span>Profile</span>
    </button>
  </mat-toolbar>
  