

@if (isModalOpen()) {
    <app-user-modal *ngIf="isModalOpen()" [profileInterface]="this.avatarInterface" [currentUserProfile]="this.currentUserProfile" [onProfilePage]="this.onProfilePage" (closeModal)="controllerUserModal()"></app-user-modal>
}

<div class="user-title-container" (click)="this.controllerUserModal()" style=" padding: 16px; border-radius: 16px; border: 3px solid var(--border-color); max-width: 200px;">
    
    <div class="col" style="gap: 0; margin: 0;">
        <div class="row" style="padding: 0; gap: 8px;">
            <div class="avatar-container" style="border: 3px solid var(--border-color)">
                <img [src]="this.avatarTitle?.avatarURL" height="50px" alt="Avatar">
            </div>  
            
            <div class="row" style="gap: 0px;">
                @if (this.avatarTitle?.avatarRank === 'Robot' || this.avatarTitle?.avatarRank === ''){
                    <div class="icon-stack hidden">
                        <img [src]="this.avatarTitle?.avatarCrownURL" alt="Crown">
                    </div>
                } @else {
                    <div class="icon-stack">
                        <img [src]="this.avatarTitle?.avatarCrownURL" alt="Crown">
                    </div>
                }
                <div class="header-medium" [ngStyle]="{'color': this.avatarTitle?.avatarCrownColor}" style="font-weight: bold;">{{this.avatarTitle?.avatarUsername}}</div>
            </div>
            
        </div>
        <div class="row" style="padding: 0; gap: 16px;">
                @if (this.avatarTitle?.avatarRank === 'Moderator' || this.avatarTitle?.avatarRank === 'Administrator')  {
                    <div class="level-container hidden" [ngStyle]="{'border': 'none'}">
                        <div class="label-small hidden" [ngStyle]="{'color': this.avatarTitle?.avatarCrownColor}">{{this.avatarTitle?.avatarLevel}}</div>
                    </div>
                } @else {
                    <div class="level-container" style="border: 3px solid var(--border-color)">
                        <div class="label-small" style="color:var(--text-color); font-weight: 800;">{{this.avatarTitle?.avatarLevel}}</div>
                    </div>
                }
            <div class="header-small" [ngStyle]="{'color': this.avatarTitle?.avatarCrownColor}" style="font-weight: bold;">
                {{this.avatarTitle?.avatarRank}}
            </div>
            <img [src]="this.avatarTitle?.avatarFlagURL" alt="Flag">
        </div>      
    </div>    
</div>




