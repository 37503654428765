import { Component } from '@angular/core';
import { UserFitnessTrackerComponent } from '../../components/user-fitness-tracker/user-fitness-tracker.component';
@Component({
  selector: 'app-fitness-tracker-page',
  standalone: true,
  imports: [UserFitnessTrackerComponent],
  templateUrl: './fitness-tracker-page.component.html',
  styleUrl: './fitness-tracker-page.component.scss'
})
export class FitnessTrackerPageComponent {

}
