export const maleAvatars: string[] = [
    '/assets/AVATARS/MALES/avatarMale-00.png',
    '/assets/AVATARS/MALES/avatarMale-01.png',
    '/assets/AVATARS/MALES/avatarMale-02.png',
    '/assets/AVATARS/MALES/avatarMale-03.png',
    '/assets/AVATARS/MALES/avatarMale-04.png',
    '/assets/AVATARS/MALES/avatarMale-05.png',
    '/assets/AVATARS/MALES/avatarMale-06.png',
    '/assets/AVATARS/MALES/avatarMale-07.png',
    '/assets/AVATARS/MALES/avatarMale-08.png',
    '/assets/AVATARS/MALES/avatarMale-09.png',
    '/assets/AVATARS/MALES/avatarMale-10.png',
    '/assets/AVATARS/MALES/avatarMale-11.png',
    '/assets/AVATARS/MALES/avatarMale-12.png',
    '/assets/AVATARS/MALES/avatarMale-13.png',
    '/assets/AVATARS/MALES/avatarMale-14.png',
    '/assets/AVATARS/MALES/avatarMale-15.png',
    '/assets/AVATARS/MALES/avatarMale-16.png',
    '/assets/AVATARS/MALES/avatarMale-17.png',
    '/assets/AVATARS/MALES/avatarMale-18.png',
    '/assets/AVATARS/MALES/avatarMale-19.png',
    '/assets/AVATARS/MALES/avatarMale-20.png',
    '/assets/AVATARS/MALES/avatarMale-21.png',
    '/assets/AVATARS/MALES/avatarMale-22.png',
    '/assets/AVATARS/MALES/avatarMale-23.png',
    '/assets/AVATARS/MALES/avatarMale-24.png',
    '/assets/AVATARS/MALES/avatarMale-25.png',
    '/assets/AVATARS/MALES/avatarMale-26.png',
    '/assets/AVATARS/MALES/avatarMale-27.png',
    '/assets/AVATARS/MALES/avatarMale-28.png',
    '/assets/AVATARS/MALES/avatarMale-29.png',
    '/assets/AVATARS/MALES/avatarMale-30.png',
    '/assets/AVATARS/MALES/avatarMale-31.png',
    '/assets/AVATARS/MALES/avatarMale-32.png',
    '/assets/AVATARS/MALES/avatarMale-33.png',
    '/assets/AVATARS/MALES/avatarMale-34.png',
    '/assets/AVATARS/MALES/avatarMale-35.png',
    '/assets/AVATARS/MALES/avatarMale-36.png',
    '/assets/AVATARS/MALES/avatarMale-37.png',
    '/assets/AVATARS/MALES/avatarMale-38.png',
    '/assets/AVATARS/MALES/avatarMale-39.png',
    '/assets/AVATARS/MALES/avatarMale-40.png',
    '/assets/AVATARS/MALES/avatarMale-41.png',
    '/assets/AVATARS/MALES/avatarMale-42.png',
    '/assets/AVATARS/MALES/avatarMale-43.png',
    '/assets/AVATARS/MALES/avatarMale-44.png',
    '/assets/AVATARS/MALES/avatarMale-45.png',
    '/assets/AVATARS/MALES/avatarMale-46.png',
    '/assets/AVATARS/MALES/avatarMale-47.png',
    '/assets/AVATARS/MALES/avatarMale-48.png',
    '/assets/AVATARS/MALES/avatarMale-49.png',
    '/assets/AVATARS/MALES/avatarMale-50.png',
    '/assets/AVATARS/MALES/avatarMale-51.png',
    '/assets/AVATARS/MALES/avatarMale-52.png',
    '/assets/AVATARS/MALES/avatarMale-53.png',
    '/assets/AVATARS/MALES/avatarMale-54.png',
    '/assets/AVATARS/MALES/avatarMale-55.png',
    '/assets/AVATARS/MALES/avatarMale-56.png',
    '/assets/AVATARS/MALES/avatarMale-57.png',
    '/assets/AVATARS/MALES/avatarMale-58.png',
    '/assets/AVATARS/MALES/avatarMale-59.png',
    '/assets/AVATARS/MALES/avatarMale-60.png',
    '/assets/AVATARS/MALES/avatarMale-61.png',
    '/assets/AVATARS/MALES/avatarMale-62.png',
    '/assets/AVATARS/MALES/avatarMale-63.png',
    '/assets/AVATARS/MALES/avatarMale-64.png',
    '/assets/AVATARS/MALES/avatarMale-65.png',
    '/assets/AVATARS/MALES/avatarMale-66.png',
    '/assets/AVATARS/MALES/avatarMale-67.png',
    '/assets/AVATARS/MALES/avatarMale-68.png',
    '/assets/AVATARS/MALES/avatarMale-69.png',
    '/assets/AVATARS/MALES/avatarMale-70.png',
    '/assets/AVATARS/MALES/avatarMale-71.png',
    '/assets/AVATARS/MALES/avatarMale-72.png',
    '/assets/AVATARS/MALES/avatarMale-73.png',
    '/assets/AVATARS/MALES/avatarMale-74.png',
    '/assets/AVATARS/MALES/avatarMale-75.png',
    '/assets/AVATARS/MALES/avatarMale-76.png',
    '/assets/AVATARS/MALES/avatarMale-77.png',
    '/assets/AVATARS/MALES/avatarMale-78.png',
    '/assets/AVATARS/MALES/avatarMale-79.png',
    '/assets/AVATARS/MALES/avatarMale-80.png',
    '/assets/AVATARS/MALES/avatarMale-81.png',
    '/assets/AVATARS/MALES/avatarMale-82.png',
    '/assets/AVATARS/MALES/avatarMale-83.png',
    '/assets/AVATARS/MALES/avatarMale-84.png',
    '/assets/AVATARS/MALES/avatarMale-85.png',
    '/assets/AVATARS/MALES/avatarMale-86.png',
    '/assets/AVATARS/MALES/avatarMale-87.png',
    '/assets/AVATARS/MALES/avatarMale-88.png',
    '/assets/AVATARS/MALES/avatarMale-89.png',
    '/assets/AVATARS/MALES/avatarMale-90.png',
    '/assets/AVATARS/MALES/avatarMale-91.png',
    '/assets/AVATARS/MALES/avatarMale-92.png',
    '/assets/AVATARS/MALES/avatarMale-93.png',
    '/assets/AVATARS/MALES/avatarMale-94.png',
    '/assets/AVATARS/MALES/avatarMale-95.png',
    '/assets/AVATARS/MALES/avatarMale-96.png',
    '/assets/AVATARS/MALES/avatarMale-97.png',
    '/assets/AVATARS/MALES/avatarMale-98.png',
    '/assets/AVATARS/MALES/avatarMale-99.png',
    '/assets/AVATARS/MALES/avatarMale-100.png',
    '/assets/AVATARS/MALES/avatarMale-101.png'
  ];
  

export const femaleAvatars: string[] = [
'/assets/AVATARS/FEMALES/avatarFemale-00.png',
'/assets/AVATARS/FEMALES/avatarFemale-01.png',
'/assets/AVATARS/FEMALES/avatarFemale-02.png',
'/assets/AVATARS/FEMALES/avatarFemale-03.png',
'/assets/AVATARS/FEMALES/avatarFemale-04.png',
'/assets/AVATARS/FEMALES/avatarFemale-05.png',
'/assets/AVATARS/FEMALES/avatarFemale-06.png',
'/assets/AVATARS/FEMALES/avatarFemale-07.png',
'/assets/AVATARS/FEMALES/avatarFemale-08.png',
'/assets/AVATARS/FEMALES/avatarFemale-09.png',
'/assets/AVATARS/FEMALES/avatarFemale-10.png',
'/assets/AVATARS/FEMALES/avatarFemale-11.png',
'/assets/AVATARS/FEMALES/avatarFemale-12.png',
'/assets/AVATARS/FEMALES/avatarFemale-13.png',
'/assets/AVATARS/FEMALES/avatarFemale-14.png',
'/assets/AVATARS/FEMALES/avatarFemale-15.png',
'/assets/AVATARS/FEMALES/avatarFemale-16.png',
'/assets/AVATARS/FEMALES/avatarFemale-17.png'
];

