import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-countdown-clock',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './countdown-clock.component.html',
  styleUrls: ['./countdown-clock.component.scss']
})
export class CountdownClockComponent implements OnInit, OnDestroy {
  timeLeft: string = '';
  intervalId: any;

  ngOnInit() {
    this.updateCountdown();
    this.intervalId = setInterval(() => {
      this.updateCountdown();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateCountdown() {
    const now = new Date();
    const estOffset = -5 * 60; // EST is UTC-5
    const utcNow = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
    const estNow = new Date(utcNow.getTime() + (estOffset * 60000));
    const nextDay = new Date(estNow);
    nextDay.setUTCDate(nextDay.getUTCDate() + 1);
    nextDay.setUTCHours(5, 0, 0, 0); // Midnight in EST

    const timeDifference = nextDay.getTime() - estNow.getTime();

    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    this.timeLeft = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(num: number) {
    return num < 10 ? `0${num}` : num.toString();
  }
}
