import { Component, inject } from '@angular/core';
import { ChallengeService } from '../../services/user-challenges.service';
import { UserTitle } from '../../models/userTitle.model';
import { UserService } from '../../services/user-interface.service';
import { ChallengeInputModuleComponent } from '../../components/challenge-input-module/challenge-input-module.component';
import { ChallengeModuleComponent } from '../../components/challenge-module/challenge-module.component';
import { UserInterface } from '../../models/user.interface';
import { ChallengeModuleInterface } from '../../models/challengeModule.interface';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';

@Component({
  selector: 'app-user-community-challenges-page',
  standalone: true,
  imports: [ChallengeInputModuleComponent, ChallengeModuleComponent, RouterModule, SharedMaterialModule],
  templateUrl: './user-community-challenges-page.component.html',
  styleUrl: './user-community-challenges-page.component.scss'
})
export class UserCommunityChallengesPageComponent {
  userInterface: UserInterface | null | undefined;
  titleDoodleman: UserTitle | undefined;
  userDailyChallenges: ChallengeModuleInterface[] = [];
  userCommunityChallenges: ChallengeModuleInterface[] = [];
  currentDate = this.today();

  userService = inject(UserService);
  challengeService = inject(ChallengeService);

  ngOnInit(): void {
    this.userInterface = this.userService.getUserInterface();
    
    if (this.userInterface) {
      this.titleDoodleman = this.userService.classifyUser(this.userInterface);
      this.loadChallenges();
    }
  }

  async loadChallenges() {
    if (this.userInterface) {
      this.userDailyChallenges = await this.challengeService.setUserDailyChallenges(this.userInterface.uid, this.currentDate);
      this.userCommunityChallenges = await this.challengeService.setCommunityDailyChallenges(this.userInterface.uid, this.currentDate);
    }
  }

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

}

