import { Component, Input, inject, OnInit } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { interval } from 'rxjs';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';
import { UserInterface } from '../../models/user.interface';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-user-daily-actions',
  standalone: true,
  imports: [SharedMaterialModule, CommonModule, RouterModule, MatIconModule],
  templateUrl: './user-daily-actions.component.html',
  styleUrls: ['./user-daily-actions.component.scss']
})
export class UserDailyActionsComponent implements OnInit {
  @Input() userInterface: UserInterface | null | undefined;
  firestore = inject(Firestore);
  flagMorningQuiz: boolean = false;
  flagNightQuiz: boolean = false;
  
  isLoading: boolean = true;

  flagPersonalEasy: boolean = false;
  flagPersonalMedium: boolean = false;
  flagPersonalHard: boolean = false;

  flagCommunityEasyOne: boolean = false;
  flagCommunityEasyTwo: boolean = false;
  flagCommunityMediumOne: boolean = false;
  flagCommunityMediumTwo: boolean = false;
  flagCommunityHardOne: boolean = false;
  flagCommunityHardTwo: boolean = false;



  personalChallenges = { totalChallenges: 0, totalChallengesCompleted: 0, challenges: [] };
  communityChallenges = { totalChallenges: 0, totalChallengesCompleted: 0, challenges: [] };

  ngOnInit(): void {
    console.log('Starting Oninit...');
    
    Promise.all([
      this.getUserDailyQuiz(),
      this.loadUserChallenges('daily-personal-challenges', 'personalChallenges').then(() => {
        this.setChallengeFlags(this.personalChallenges.challenges, 'personal');
      }),
      this.loadUserChallenges('daily-community-challenges', 'communityChallenges').then(() => {
        this.setChallengeFlags(this.communityChallenges.challenges, 'community');
      })
    ]).then(() => {
      this.isLoading = false;
      console.log(this.flagMorningQuiz);
      console.log('Flags set, loading UI...');
    });
  }
  
  
  setChallengeFlags(challenges: any[], type: 'personal' | 'community') {
    challenges.forEach(challenge => {
      if (challenge.challenge_status) {  // Check if the challenge status is true
        if (type === 'personal') {
          if (challenge.challenge_difficulty === 'easy') this.flagPersonalEasy = true;
          if (challenge.challenge_difficulty === 'medium') this.flagPersonalMedium = true;
          if (challenge.challenge_difficulty === 'hard') this.flagPersonalHard = true;
        } else if (type === 'community') {
          if (challenge.challenge_difficulty === 'easy') {
            if (!this.flagCommunityEasyOne) this.flagCommunityEasyOne = true;
            else this.flagCommunityEasyTwo = true;
          }
          if (challenge.challenge_difficulty === 'medium') {
            if (!this.flagCommunityMediumOne) this.flagCommunityMediumOne = true;
            else this.flagCommunityMediumTwo = true;
          }
          if (challenge.challenge_difficulty === 'hard') {
            if (!this.flagCommunityHardOne) this.flagCommunityHardOne = true;
            else this.flagCommunityHardTwo = true;
          }
        }
      }
    });
  }
  
  
  

  async getUserDailyQuiz() {
    const currentDate = this.today();
    console.log(currentDate);
    const docSnapMorningQuiz = await getDoc(doc(this.firestore, `user-daily-quizzes/${this.userInterface?.uid}/${currentDate}/morning-quiz`));
    const docSnapNightQuiz = await getDoc(doc(this.firestore, `user-daily-quizzes/${this.userInterface?.uid}/${currentDate}/night-quiz`));
    console.log(docSnapMorningQuiz);
    console.log(docSnapMorningQuiz.data())
    this.flagMorningQuiz = docSnapMorningQuiz.exists();
    this.flagNightQuiz = docSnapNightQuiz.exists();
  }

  async loadUserChallenges(challengeType: string, target: 'personalChallenges' | 'communityChallenges') {
    const { totalChallenges, totalChallengesCompleted, challenges } = await this.getUserChallenges(challengeType);
    this[target].totalChallenges = totalChallenges;
    this[target].totalChallengesCompleted = totalChallengesCompleted;
    this[target].challenges = challenges;

  }

  async getUserChallenges(challengeType: string) {
    const currentDate = this.today();
    const docSnap = await getDoc(doc(this.firestore, `user-daily-challenges/${this.userInterface?.uid}/${challengeType}/${currentDate}`));
    const challenges = docSnap.exists() ? docSnap.data()['challenges'] || [] : [];

    const totalChallengesCompleted = challenges.filter((c: any) => c.challenge_status).length;
    const totalChallenges = challenges.length || 3;

    return { totalChallenges, totalChallengesCompleted, challenges };
  }

  getPersonalEasyColor(): string {
    return this.flagPersonalEasy ? 'var(--secondary-color)' : '#CD7F32';
  }
  
  getPersonalMediumColor(): string {
    return this.flagPersonalMedium ? 'var(--secondary-color)' : '#D8D8D8';
  }
  
  getPersonalHardColor(): string {
    return this.flagPersonalHard ? 'var(--secondary-color)' : '#D3AF37';
  }

 


  today(): string {
    const options = { timeZone: 'America/New_York', year: 'numeric', month: '2-digit', day: '2-digit' } as const;
    const formatter = new Intl.DateTimeFormat('en-US', options);
    
    const now = new Date();
    const parts = formatter.formatToParts(now);
    
    const year = parts.find(p => p.type === 'year')!.value;
    const month = parts.find(p => p.type === 'month')!.value;
    const day = parts.find(p => p.type === 'day')!.value;
    
    return `${year}-${month}-${day}`;
  }
  
  
}



