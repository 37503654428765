import { Component, EventEmitter, Output, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { maleAvatars, femaleAvatars } from '../../../assets/AVATARS/avatar-list';

@Component({
  selector: 'app-avatar-mystery-box',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatCardModule],
  templateUrl: './avatar-mystery-box.component.html',
  styleUrls: ['./avatar-mystery-box.component.scss']
})
export class AvatarMysteryBoxComponent {
  avatar_one = '/assets/AVATARS/MALES/avatarMale-00.png';
  avatar_two = '/assets/AVATARS/MALES/avatarMale-01.png';
  avatar_three = '/assets/AVATARS/MALES/avatarMale-02.png';

  maleAvatars = maleAvatars;
  femaleAvatars = femaleAvatars;

  selectedAvatar: string | null = null;
  isBoxOpen = false;

  mysteryBoxSpin = false;

  @Output() avatarSelected = new EventEmitter<string>();

  mysteryBoxEngine() {
    const avatarList: string[] = [...this.maleAvatars];
    const totalAvatars = avatarList.length;

    const randomIndex = Math.floor(Math.random() * totalAvatars);
    this.selectedAvatar = avatarList[randomIndex];

    this.avatarSelected.emit(this.selectedAvatar);

    this.mysteryBoxSpin = true;
    this.isBoxOpen = true;
    setTimeout(() => {
      this.isBoxOpen = false;
    }, 2000); // Close the box after 2 seconds
  }
}

