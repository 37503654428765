import { Component, OnInit, inject } from '@angular/core';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';
import { UserWeeklyProgressComponent } from '../../components/user-weekly-progress/user-weekly-progress.component';
import { CommunityStatsModuleComponent } from '../../components/community-stats-module/community-stats-module.component';
import { UserDailyProgressComponent } from '../../components/user-daily-progress/user-daily-progress.component';
import { SetUserProfileComponent } from '../../components/set-user-profile/set-user-profile.component';
import { Firestore, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { UserService } from '../../services/user-interface.service';
import { ChallengeService } from '../../services/user-challenges.service';
import { UserInterface } from '../../models/user.interface';
import { UserTitle } from '../../models/userTitle.model';
import { Timestamp } from 'firebase/firestore';
import { ChallengeModuleInterface } from '../../models/challengeModule.interface';
import { ChallengeInputModuleComponent } from '../../components/challenge-input-module/challenge-input-module.component';
import { ChallengeModuleComponent } from '../../components/challenge-module/challenge-module.component';
import { UserDailyActionsComponent } from '../../components/user-daily-actions/user-daily-actions.component';
import { DailyQuizStatDisplayComponent } from '../../components/daily-quiz-stat-display/daily-quiz-stat-display.component';
import { UserChatInterfaceComponent } from '../../components/user-chat-interface/user-chat-interface.component';
import { ProgressBarComponent } from '../../shared/progress-bar/progress-bar.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [SharedMaterialModule,
    UserWeeklyProgressComponent,
    CommunityStatsModuleComponent,
    UserDailyProgressComponent,
    SetUserProfileComponent,
    ChallengeInputModuleComponent,
    ChallengeModuleComponent,
    UserDailyActionsComponent,
    DailyQuizStatDisplayComponent,
    UserChatInterfaceComponent,
    ProgressBarComponent],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  userInterface: UserInterface | null | undefined;
  userTitle: UserTitle | undefined;
  userDailyChallenges: ChallengeModuleInterface[] = [];
  userCommunityChallenges: ChallengeModuleInterface[] = [];
  currentDate = this.today();

  userService = inject(UserService);
  challengeService = inject(ChallengeService);
  fireStore = inject(Firestore);

  async ngOnInit(): Promise<void> {
    this.userInterface = this.userService.getUserInterface();

    if (this.userInterface) {
      await this.handleUserLoginStreak(this.userInterface.uid);
      
      // Re-fetch the updated user information and classify the user
      this.userTitle = this.userService.classifyUser(this.userInterface);
      
      await this.loadChallenges();
    }
  }

  async loadChallenges() {
    if (this.userInterface) {
      this.userDailyChallenges = await this.challengeService.setUserDailyChallenges(this.userInterface.uid, this.currentDate);
      this.userCommunityChallenges = await this.challengeService.setCommunityDailyChallenges(this.userInterface.uid, this.currentDate);
    }
  }

async handleUserLoginStreak(userId: string): Promise<void> {
  const docRefUserInterface = doc(this.fireStore, `user-interfaces/${userId}`);
  const docSnapUserInterface = await getDoc(docRefUserInterface);

  if (docSnapUserInterface.exists()) {
    const data = docSnapUserInterface.data();
    const lastLoginTimestamp = data['lastLoginDate'];
    const userStreak = data['userStreak'] || 0;

    if (lastLoginTimestamp) {
      const lastLoginDate = lastLoginTimestamp.toDate(); // Convert to JavaScript Date
      const currentDate = new Date();
      const yesterday = new Date();
      yesterday.setDate(currentDate.getDate() - 1);

      // Reset time to midnight for accurate comparison
      lastLoginDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);
      yesterday.setHours(0, 0, 0, 0);

      // Check if the last login date is today
      if (lastLoginDate.getTime() !== currentDate.getTime()) {
        let updatedStreak = userStreak;

        if (lastLoginDate.getTime() === yesterday.getTime()) {
          // Last login was yesterday, increment the streak
          updatedStreak += 1;
        } else if (lastLoginDate.getTime() < yesterday.getTime()) {
          // Last login was more than a day ago, reset the streak
          updatedStreak = 0;
        }

        // Update the Firestore document with the new streak and last login date
        await setDoc(docRefUserInterface, {
          lastLoginDate: Timestamp.fromDate(currentDate),
          userStreak: updatedStreak,
        }, { merge: true });

        console.log(`Updated user streak: ${updatedStreak}`);
        console.log("Updated last login date to:", currentDate);

        // Re-fetch the updated user information
        this.userInterface = this.userService.getUserInterface();
      } else {
        console.log("User already logged in today, no update necessary.");
      }
    }
  } else {
    console.log("No such document!");
  }
}

today(): string {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
}


