import { Component, inject, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChallengeModuleInterface } from '../../models/challengeModule.interface';
import { Firestore, doc, getDoc, updateDoc, setDoc } from '@angular/fire/firestore';
import { UserInterface } from '../../models/user.interface';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-challenge-module',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatButtonModule, MatIconModule, MatSlideToggleModule, FormsModule],
  templateUrl: './challenge-module.component.html',
  styleUrls: ['./challenge-module.component.scss']
})
export class ChallengeModuleComponent implements OnInit {
  @Input() challengeModuleTitle?: string;
  @Input() challenges: ChallengeModuleInterface[] = [];
  firestore = inject(Firestore);
  authService = inject(AuthService);
  userInterface: UserInterface | null | undefined;
  localChallengeState: { [key: number]: boolean } = {};

  ngOnInit(): void {
    this.userInterface = this.authService.currentUserSig();
    
  }

  onToggleChange(challengeId: number, status: boolean) {
    this.localChallengeState[challengeId] = status;
  }

  async submitChallenge(challenge: ChallengeModuleInterface) {
    try {
      if (this.userInterface) {
        const localStatus = this.localChallengeState[challenge.challenge_id];
        if (localStatus !== undefined) {
          challenge.challenge_status = localStatus;
          const currentDate = this.today();
          const challengeDocPath = this.challengeModuleTitle?.startsWith('Community')
            ? `user-daily-challenges/${this.userInterface.uid}/daily-community-challenges/${currentDate}`
            : `user-daily-challenges/${this.userInterface.uid}/daily-personal-challenges/${currentDate}`;
          
          const userChallengesDocRef = doc(this.firestore, challengeDocPath);
          const userChallengesSnap = await getDoc(userChallengesDocRef);

          if (userChallengesSnap.exists()) {
            const challenges = userChallengesSnap.data()['challenges'];
            const updatedChallenges = challenges.map((ch: ChallengeModuleInterface) => 
              ch.challenge_id === challenge.challenge_id ? { ...ch, challenge_status: localStatus } : ch
            );

            await updateDoc(userChallengesDocRef, { challenges: updatedChallenges });
            

            const userScoreMasterDocRef = doc(this.firestore, `user-score-master/${this.userInterface.uid}`);
            const userScoreSnap = await getDoc(userScoreMasterDocRef);
            const challengePointTotal = challenge.challenge_difficulty === 'easy' ? 1 : challenge.challenge_difficulty === 'medium' ? 2 : 3;

            if (userScoreSnap.exists()) {
              const points = userScoreSnap.data()['points'] || 0;
              const pointsPerLevel = 100 / 7;

              // Calculate the updated level
              const updatedlevel = Math.floor(points / pointsPerLevel);

              // Calculate the updated experience left as a fraction of the next level
              const updatedExperience = (points % pointsPerLevel) / pointsPerLevel;
              const userDocRef = doc(this.firestore, `user-interfaces/${this.userInterface.uid}`);
              await updateDoc(userDocRef, { 
                userLevel: updatedlevel,
                userExperience: updatedExperience,
              });

              // Update the local signal
              const updatedUserInterface: UserInterface = {
                ...this.userInterface,
                userLevel: updatedlevel,
                userExperience: updatedExperience,
              };

              this.authService.currentUserSig.set(updatedUserInterface);
              await updateDoc(userScoreMasterDocRef, { points: points + challengePointTotal });
            } else {
              await setDoc(userScoreMasterDocRef, { points: challengePointTotal });
            }

            // Update community statistics
            await this.updateCommunityStatistics(this.userInterface.uid, challengePointTotal);
            
            delete this.localChallengeState[challenge.challenge_id]; // Clear local state after submission
          } else {
            console.error('User challenges document does not exist for the current date');
          }
        }
      }
    } catch (error) {
      console.error('Error updating challenge status:', error);
    }
  }

  async updateCommunityStatistics(userId: string, points: number) {
    const currentDate = this.today();
    const statisticsDocRef = doc(this.firestore, `statistics-community-activity/${currentDate}`);
    const statisticsSnap = await getDoc(statisticsDocRef);

    if (statisticsSnap.exists()) {
      const data = statisticsSnap.data();
      const userIds = data['userIds'] || [];
      const isNewUser = !userIds.includes(userId);

      const updateData: any = {
        totalPoints: data['totalPoints'] + points,
        totalChallengesCompleted: data['totalChallengesCompleted'] + 1,
      };

      if (isNewUser) {
        updateData.totalUniqueUsers = data['totalUniqueUsers'] + 1;
        updateData.userIds = [...userIds, userId];
      }

      await updateDoc(statisticsDocRef, updateData);
    } else {
      const newStatistics = {
        totalUniqueUsers: 1,
        totalPoints: points,
        totalChallengesCompleted: 1,
        userIds: [userId]
      };

      await setDoc(statisticsDocRef, newStatistics);
    }
  }

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  capitalizeFirstLetter(text: string | null): string {
    return text ? text.charAt(0).toUpperCase() + text.slice(1) : '';
  }

  hasNullTitle(): boolean {
    return this.challenges.length === 0 || this.challenges.some(challenge => challenge.challenge_title === '');
  }
}

