import { UserInterface } from "../user.interface"

export const profileDoodleman: UserInterface = { 
    uid: 'ADMIN_01',
    email: 'doodleman@thewerkitapp.com',
    userRole: 'mod',
    username: 'Doodleman',
    userAvatarURL: '/assets/avatarDoodleman.png',
    userCountry: 'Ireland',
    userLevel: 100,
    userExperience: 0.5,
    userBirthstone: 'Moonstone',
    userWerk: 'Retail Industry',
    userBio: 'Irish Stand up!  We aint going half speed over here!',
    signupDate: null,
    lastLoginDate: null,
    userStreak: 0,
  }

 