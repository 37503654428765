<div class="col" style="margin-bottom: 10px;">
    <app-countdown-clock></app-countdown-clock>
    <span class="countdown-header" style="color: var(--text-color); border-bottom: 2px solid var(--brand-color);">Community Daily Activity</span>
    <div class="row">
        <div class="stat-box">
            <p class="stat-header">
                Total Users
           </p> 
            <p class="stat-value">
                {{this.totalUniqueUsers}}
            </p> 
        </div>
        <div class="stat-box">
            <p class="stat-header">
                Points
           </p> 
            <p class="stat-value">
                {{this.totalPoints}}
            </p> 
        </div>
        <div class="stat-box">
            <p class="stat-header">
                Challenges 
           </p> 
            <p class="stat-value">
                {{this.totalChallengesCompleted}}
            </p> 
        </div>
    </div>


  </div>
  