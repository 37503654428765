import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    
    const currentUser = this.authService.currentUserSig();
    
    if (currentUser) {
      
      this.router.navigate(['/home']);
      return false; // Prevent navigation to the landing page
    }
    return true; // Allow navigation to the landing page
  }
}
