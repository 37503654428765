import { Injectable, inject, signal } from "@angular/core";
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, user, User, GoogleAuthProvider, signInWithPopup } from "@angular/fire/auth";
import { Firestore, doc, setDoc, getDoc } from "@angular/fire/firestore";
import { from, Observable } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { UserInterface } from "../models/user.interface";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private firebaseAuth: Auth = inject(Auth);
    private firestore: Firestore = inject(Firestore);
    
    user$ = user(this.firebaseAuth);
    currentUserSig = signal<UserInterface | null | undefined>(undefined);
    
    constructor() {
        this.user$.pipe(
            switchMap(user => {
                if (user) {
                    return this.loadUserInterface(user.uid).then(profile => {
                        this.currentUserSig.set(profile);
                        return profile;
                    });
                } else {
                    this.currentUserSig.set(null);
                    return from([null]);
                }
            })
        ).subscribe();
    }

    async loadUserInterface(uid: string): Promise<UserInterface | null> {
        const userDocRef = doc(this.firestore, `user-interfaces/${uid}`);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
            const data = userDoc.data() as UserInterface;
            const userProfile: UserInterface = {
                uid: data.uid,
                email: data.email,
                userRole: data.userRole,
                username: data.username || '',
                userAvatarURL: data.userAvatarURL || '',
                userCountry: data.userCountry || '',
                userLevel: data.userLevel,
                userExperience: data.userExperience,
                userStreak: data.userStreak,
                userBirthstone: data.userBirthstone || '',
                userWerk: data.userWerk || '',
                userBio: data.userBio || '',
                signupDate: data.signupDate,
                lastLoginDate: data.lastLoginDate
            };

            return userProfile;
        } else {
            console.error('No such document!');
            return null;
        }
    }

    register(email: string, password: string): Observable<void> {
        return from(
            createUserWithEmailAndPassword(this.firebaseAuth, email, password)
        ).pipe(
            switchMap(response => {
                const uid = response.user.uid;
                const userDoc = doc(this.firestore, `user-interfaces/${uid}`);
        
                const userProfile: UserInterface = {
                    uid,
                    email: response.user.email!,
                    userRole: 'user',
                    username: '',
                    userAvatarURL: '',
                    userCountry: '',
                    userLevel: 0,
                    userExperience: 0,
                    userStreak: 0,
                    userBirthstone: '',
                    userWerk: '',
                    userBio: 'Welcome to the application :) Thanks for signing up. Whats your story?',
                    signupDate: new Date(),
                    lastLoginDate: new Date(),
                    
                };
                return from(setDoc(userDoc, userProfile)).pipe(
                    switchMap(() => updateProfile(response.user, { displayName: '' }))
                );
            }),
            catchError(error => {
                console.error('Registration error:', error);
                throw error;
            })
        );
    }

    login(email: string, password: string): Observable<void> {
        const promise = signInWithEmailAndPassword(
            this.firebaseAuth,
            email,
            password
        ).then(() => {});
        return from(promise);
    }

    logout(): Observable<void> {
        return from(signOut(this.firebaseAuth)).pipe(
            catchError(error => {
                console.error('Logout error:', error);
                throw error;
            })
        );
    }

    signInWithGoogle(): Observable<void> {
        const provider = new GoogleAuthProvider();
        return from(signInWithPopup(this.firebaseAuth, provider)).pipe(
            switchMap(response => {
                const uid = response.user.uid;
                const userDocRef = doc(this.firestore, `user-interfaces/${uid}`);
                return getDoc(userDocRef).then(userDoc => {
                    if (!userDoc.exists()) {
                        const userProfile: UserInterface = {
                            uid,
                            email: response.user.email!,
                            userRole: 'user',
                            username: '',
                            userAvatarURL:'',
                            userCountry: '',
                            userLevel: 0,
                            userExperience: 0,
                            userBirthstone: '',
                            userWerk: '',
                            userBio: 'Welcome to the application :) Thanks for signing up. Whats your story?',
                            signupDate: new Date(),
                            lastLoginDate: new Date(),
                            userStreak: 0
                        };
                        return setDoc(userDocRef, userProfile);
                    }
                    return Promise.resolve();
                }).then(() => {
                    return this.loadUserInterface(uid).then(profile => {
                        this.currentUserSig.set(profile);
                    });
                });
            }),
            catchError(error => {
                console.error('Google Sign-In error:', error);
                throw error;
            })
        );
    }
}

