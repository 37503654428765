<div class="chat-interface-container">
    <div class="chat-message-box" #messagebox>
      <div *ngFor="let message of chatMessages" class="chat-message">
        <img [src]="message.avatarURL" class="avatar-icon" [routerLink]="['/profile/', message.avatarUsername]"/>
        <div class="message-content">
          <p class="message-header">
            @if (message.avatarCrownURL != ''){
                <img [src]="message.avatarCrownURL" class="crown-icon"/>
            }
            <strong [ngStyle]="{ 'color': message.avatarCrownColor }" [routerLink]="['/profile/', message.avatarUsername]">{{ message.avatarUsername }}</strong>
            <img [src]="message.avatarFlagURL" width="20px;" style="margin-top: 5px;"/>
            
            <span class="timestamp" style="margin-left: 20px;">{{ message.timestamp | date:'shortTime' }}</span>
          </p>
          <p class="message-text">{{ message.message }}</p>
        </div>
      </div>
    </div>
  
    <div class="chat-action-bar">
        <div class="user-chat-input">
            <input type="text" placeholder="Type a message..." [(ngModel)]="chatMessage" (input)="onMessageInput()" (keyup.enter)="sendChatMessage(chatMessage)" maxlength="140">
          <div class="char-counter">{{ chatMessage.length }}/140</div>
        </div>
        <div class="user-submit-button">
          <button (click)="sendChatMessage(chatMessage)" [disabled]="chatMessage.length > 140">Send</button>
        </div>
      </div>
      
  </div>
  