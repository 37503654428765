@if (!setActiveQuizMorning && !setActiveQuizNight) {
    <div class="page">
        <app-daily-quiz-stat-display [userInterface]="this.userInterface" />
        
        <div class="row">
            <div class="quiz-container">
                <div class="quiz-header">Morning Quiz</div>
                @if (loadingMorningQuiz) {
                    <mat-spinner />
                } @else {
                    <div class="row">
                        @if (!flagQuizMorning) {
                            <mat-icon color="warn" style="color: lightcoral;">circle</mat-icon>
                            <div class="quiz-sub-header" style="color: lightcoral;">2 points</div>
                        } @else {
                            <mat-icon style="color: lightgreen;">circle</mat-icon>
                            <div class="quiz-sub-header" style="color: lightgreen;">2 points</div>
                        }                
                    </div>   
                    @if (!flagQuizMorning) {
                        <button mat-flat-button color="primary" (click)="setScreenMorningQuiz()">Take Quiz!</button>
                    }
                }
                
            </div>

            <div class="quiz-container">
                <div class="quiz-header">Night Quiz</div>
                @if (loadingNightQuiz){
                 <mat-spinner />   
                } @else {
                    <div class="row">
                    @if (!flagQuizNight) {
                        <mat-icon color="warn" style="color: lightcoral;">circle</mat-icon>
                        <div class="quiz-sub-header" style="color: lightcoral;">2 points</div>
                    } @else {
                        <mat-icon style="color: lightgreen;">circle</mat-icon>
                        <div class="quiz-sub-header" style="color: lightgreen;">2 points</div>
                    }                
                </div>   
                @if (!flagQuizNight) {
                    <button mat-flat-button color="primary" (click)="setScreenNightQuiz()">Take Quiz!</button>
                }
                }
                
            </div>

            
        </div>
        <mat-icon style="color: var(--secondary-color); margin-top: 10px;" [routerLink]="['/home']">arrow_back</mat-icon>
    </div>
} @if (setActiveQuizMorning) {
    <div class="page">
        <app-user-title [avatarInterface]="interfaceDoodleman"></app-user-title>
        <div class="dialog-box">
          <p>Me like coffee.</p>
          <p>NOM NOM NOM.</p>
          <p>TIME TO GET AFTER IT!</p>
        </div>
        <form [formGroup]="quizMorningForm" (ngSubmit)="submitQuiz(true)" class="col">
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Wake Up Time</mat-label>
                <mat-select formControlName="morningWakeUp">
                  <mat-option *ngFor="let time of wakeupList" [value]="time">{{time}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('morningWakeUp')?.hasError('required')">Waking up is required.</mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Bed Time</mat-label>
                <mat-select formControlName="morningBedTime">
                  <mat-option *ngFor="let bedtime of bedtimeList" [value]="bedtime">{{bedtime}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('morningWakeUp')?.hasError('required')">Sleeping is required.</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Morning Feeling</mat-label>
                <mat-select formControlName="morningFeeling">
                  <mat-option *ngFor="let feeling of feelingList" [value]="feeling">{{feeling}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('morningFeeling')?.hasError('required')">Are you a zombie?</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Morning Motivation</mat-label>
                <mat-select formControlName="morningMotivation">
                  <mat-option *ngFor="let motivation of motivationList" [value]="motivation">{{motivation}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('morningMotivation')?.hasError('required')">Motivation is required.</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="full-width">
                <mat-label >What's important today?</mat-label>
                <textarea matInput formControlName="morningImportance"></textarea>
            </mat-form-field>
    
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Write a message!</mat-label>
                <textarea matInput formControlName="morningMotivationalMessage"></textarea>
            </mat-form-field>
    
            <button mat-raised-button color="primary" type="submit">Submit Quiz</button>
        </form>    
    </div>
    }@if (setActiveQuizNight){
        <div class="page">
            <app-user-title [avatarInterface]="interfaceDoodleman"></app-user-title>
            <div class="dialog-box">
              <p>Me like coffee.</p>
              <p>NOM NOM NOM.</p>
              <p>TIME TO GET AFTER IT!</p>
            </div>
            <form [formGroup]="quizNightForm" (ngSubmit)="submitQuiz(false)" class="col" style="width: 325px;">
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Daily Score</mat-label>
                <mat-select formControlName="nightDayScore">
                  <mat-option *ngFor="let score of nightDayScoreList" [value]="score">{{score}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('nightDayScore')?.hasError('required')">You must score your day!</mat-error>
              </mat-form-field>
          
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Productivity Score</mat-label>
                <mat-select formControlName="nightProductivityFeeling">
                  <mat-option *ngFor="let feeling of nightProductivityFeelingList" [value]="feeling">{{feeling}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('nightProductivityFeeling')?.hasError('required')">You must score your productivity!</mat-error>
              </mat-form-field>
          
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Tomorrow Feeling</mat-label>
                <mat-select formControlName="nightTomorrowFeeling">
                  <mat-option *ngFor="let feeling of nightTomorrowFeelingList" [value]="feeling">{{feeling}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('nightTomorrowFeeling')?.hasError('required')">Are you a zombie?</mat-error>
              </mat-form-field>
          
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Rate your day!</mat-label>
                <mat-select formControlName="nightDayRating">
                  <mat-option *ngFor="let rating of nightDayRatingList" [value]="rating">{{rating}}</mat-option>
                </mat-select>
                <mat-error *ngIf="quizMorningForm.get('morningMotivation')?.hasError('required')">You must rate your day!</mat-error>
              </mat-form-field>
          
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>What's important tomorrow?</mat-label>
                <textarea matInput formControlName="nightImportance" rows="3"></textarea>
              </mat-form-field>
          
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Write a message!</mat-label>
                <textarea matInput formControlName="nightMotivationalMessage" rows="3"></textarea>
              </mat-form-field>
          
              <button mat-raised-button color="primary" type="submit">Submit Quiz</button>
            </form>    
          </div>          
    }
