import { Component, Input, OnInit, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { format } from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-daily-quiz-timeseries',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './daily-quiz-timeseries.component.html',
  styleUrls: ['./daily-quiz-timeseries.component.scss']
})
export class DailyQuizTimeseriesComponent implements OnInit {
  @Input() userQuizStats!: {
    quizDates: string[],
    morningWakeUpTimes: string[],
    morningBedTimes: string[],
    bedTimeMinutes: number[],
    wakeUpMinutes: number[],
    sleepTimeMinutes: number[],
    awakeTimeMinutes: number[],
    avgBedTimeMinutes?: number,
    avgWakeUpTimeMinutes?: number ,
    avgSleepTimeMinutes?: number,
    avgAwakeTimeMinutes?: number,
  };

  @ViewChild('myChart', { static: true }) myChart!: ElementRef<HTMLCanvasElement>;
  
  colorPrimary: string = "rgb(61, 55, 153, 1)";
  colorPrimaryFill: string = "rgb(61, 55, 153, 0.25)";
  colorSecondary: string = "rgb(128, 125, 174, 1)";
  colorText: string = '#FBFBFD';

  chart: any;

  destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      console.log('Chart destroyed.');
    }
  }

  ngOnDestroy() {
    this.destroyChart();
  }

  ngOnInit() {
    this.initializeChart();
  }

  initializeChart() {
    console.log("Initializing Chart");
    Chart.register(...registerables, annotationPlugin);
    
    const annotations: any = {};

    const avgBedTimeMinutes = this.userQuizStats.avgBedTimeMinutes ?? NaN;
    const avgWakeUpTimeMinutes = this.userQuizStats.avgWakeUpTimeMinutes ?? NaN;
    const avgSleepTimeMinutes = this.userQuizStats.avgSleepTimeMinutes ?? NaN;
  
    if (!isNaN(avgBedTimeMinutes)) {
      annotations.avgBedTimeLine = {
        type: 'line',
        yMin: this.userQuizStats.avgBedTimeMinutes,
        yMax: this.userQuizStats.avgBedTimeMinutes,
        borderColor: this.colorPrimary,
        borderWidth: 0,
        label: {
          content: `Average Bed Time: ${this.formatMinutesToTime(this.userQuizStats.avgBedTimeMinutes)}`,
          position: 'center',
          backgroundColor: this.colorPrimary,
          color: this.colorText,
          display: true,
          rotation: 0,
          font: {
            size: 14,
            family: 'Arial',
            weight: 'bold'
          },
          padding: 6
        }
      };
    }
  
    if (!isNaN(avgWakeUpTimeMinutes)) {
      annotations.avgWakeUpTimeLine = {
        type: 'line',
        yMin: this.userQuizStats.avgWakeUpTimeMinutes,
        yMax: this.userQuizStats.avgWakeUpTimeMinutes,
        borderColor: this.colorPrimary,
        borderWidth: 0,
        label: {
          content: `Average Wake Up: ${this.formatMinutesToTime(this.userQuizStats.avgWakeUpTimeMinutes)}`,
          position: 'center',
          backgroundColor: this.colorPrimary,
          color: this.colorText,
          display: true,
          rotation: 0,
          font: {
            size: 14,
            family: 'Arial',
            weight: 'bold'
          },
          padding: 6
        }
      };
    }
  
    if (!isNaN(avgSleepTimeMinutes)) {
      annotations.avgSleepTimeLine = {
        type: 'line',
        yMin: 0,
        yMax: 0,
        borderColor: "#90EE90",
        borderWidth: 0,
        label: {
          content: `Average Sleep: ${this.formatMinutesToDuration(this.userQuizStats.avgSleepTimeMinutes)}`,
          position: 'center',
          backgroundColor: 'transparent',
          borderColor: '#90EE90',
          borderWidth: 1,
          color: '#90EE90',
          display: true,
          rotation: 0,
          font: {
            size: 14,
            family: 'Arial',
            weight: 'bold'
          },
          padding: 6
        }
      };
    }
  
    this.chart = new Chart(this.myChart.nativeElement, {
      type: 'line',
      data: {
        labels: this.userQuizStats.quizDates,
        datasets: [
          {
            label: 'Bed Time Minutes',
            data: this.userQuizStats.bedTimeMinutes,
            borderColor: this.colorPrimary,
            backgroundColor: this.colorPrimaryFill,
            fill: '1'
          },
          {
            label: 'Wake Up Minutes',
            data: this.userQuizStats.wakeUpMinutes,
            borderColor: this.colorPrimary,
            backgroundColor: this.colorPrimaryFill,
          }
        ]
      },
      options: {
        scales: {
          x: {
            display: false
          },
          y: {
            min: 0,
            max: 1440,
            ticks: {
              stepSize: 180,
              color: this.colorText,
              font: {
                weight: 'bold'
              },
              callback: function(tickValue: string | number) {
                if (typeof tickValue === 'number') {
                  let hours = Math.floor(tickValue / 60);
                  const minutes = tickValue % 60;
  
                  if (hours === 0 && minutes === 0) {
                    return '12:00 AM';
                  }
                  if (hours === 12 && minutes === 0) {
                    return '12:00 PM';
                  }
                  if (hours === 24 && minutes === 0) {
                    return '12:00 AM';
                  }
  
                  const ampm = hours >= 12 ? 'PM' : 'AM';
                  hours = hours % 12;
                  hours = hours ? hours : 12;
                  return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
                }
                return tickValue;
              }
            },
          }
        },
        plugins: {
          legend: {
            display: false
          },
          annotation: {
            annotations: annotations
          }
        }
      }
    });
  }
  
  
  
  formatMinutesToTime(minutes: number | undefined): string {
    if (minutes === undefined) return 'N/A';
  
    // Round to the nearest minute
    const roundedMinutes = Math.round(minutes);
  
    const totalHours = Math.floor(roundedMinutes / 60);
    const mins = roundedMinutes % 60;
  
    // Special handling for exactly midnight (0:00) and noon (12:00)
    if (totalHours === 0 && mins === 0) {
      return '12:00 AM';  // Midnight
    } else if (totalHours === 12 && mins === 0) {
      return '12:00 PM';  // Noon
    }
  
    // Determine AM/PM period
    const period = totalHours >= 12 ? ' PM' : ' AM';
  
    // Format hours
    const hours = totalHours % 12 === 0 ? 12 : totalHours % 12;
    const formattedMinutes = mins < 10 ? `0${mins}` : mins;
  
    return `${hours}:${formattedMinutes}${period}`;
  }
  
  
  

  formatMinutesToDuration(minutes: number | undefined): string {
    if (minutes === undefined) return 'N/A';

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} hours, ${mins} minutes`;
  }
  
  
  
  
  
}

