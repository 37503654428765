import { Injectable, inject } from '@angular/core';
import { Firestore, doc, getDoc, setDoc } from '@angular/fire/firestore';
import { ChallengeModuleInterface } from '../models/challengeModule.interface';

@Injectable({
  providedIn: 'root',
})
export class ChallengeService {
  firestore = inject(Firestore);

  async setUserDailyChallenges(userId: string, currentDate: string): Promise<ChallengeModuleInterface[]> {
    const userDocRef = doc(this.firestore, `user-daily-challenges/${userId}`);
    const userDailyChallengesDocRef = doc(userDocRef, `daily-personal-challenges/${currentDate}`);
    const userDailyChallengesSnap = await getDoc(userDailyChallengesDocRef);

    if (!userDailyChallengesSnap.exists()) {
      const userChallengeDeckDocRef = doc(this.firestore, `user-challenge-deck/${userId}`);
      const userChallengeDeckSnap = await getDoc(userChallengeDeckDocRef);

      if (userChallengeDeckSnap.exists()) {
        const userChallengeDeck = userChallengeDeckSnap.data();
        const challenges = [
          userChallengeDeck['easy'],
          userChallengeDeck['medium'],
          userChallengeDeck['hard']
        ];

        const allTitlesFilled = challenges.every(challenge => challenge.challenge_title !== '');

        if (allTitlesFilled) {
          await setDoc(userDailyChallengesDocRef, { challenges });
          return challenges;
        } else {
          console.log('You must have 3 personal challenges to be assigned challenges!');
          return [];
        }
      } else {
        console.log('You must update your user challenge deck to be assigned personal challenges!');
        return [];
      }
    } else {
      return userDailyChallengesSnap.data()?.['challenges'] ?? [];
    }
  }

  async setCommunityDailyChallenges(userId: string, currentDate: string): Promise<ChallengeModuleInterface[]> {
    const userDocRef = doc(this.firestore, `user-daily-challenges/${userId}`);
    const userCommunityChallengesDocRef = doc(userDocRef, `daily-community-challenges/${currentDate}`);
    const userCommunityChallengesSnap = await getDoc(userCommunityChallengesDocRef);

    if (!userCommunityChallengesSnap.exists()) {
      const communityChallengeDeckDocRef = doc(this.firestore, `community-daily-challenges/${currentDate}`);
      const communityChallengeDeckSnap = await getDoc(communityChallengeDeckDocRef);

      if (communityChallengeDeckSnap.exists()) {
        const communityChallengeDeck = communityChallengeDeckSnap.data();
        const challenges: ChallengeModuleInterface[] = communityChallengeDeck['challenges'];

        const allTitlesFilled = challenges.every(challenge => challenge.challenge_title !== null);

        if (allTitlesFilled) {
          await setDoc(userCommunityChallengesDocRef, { challenges });
          return challenges;
        } else {
          console.log('All community challenges must have titles to be assigned!');
          return [];
        }
      } else {
        console.log('Community challenge deck is not available for the current date.');
        return [];
      }
    } else {
      return userCommunityChallengesSnap.data()?.['challenges'] ?? [];
    }
  }
}
