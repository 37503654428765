import { Component, inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Firestore, doc, setDoc, arrayUnion, onSnapshot, Unsubscribe } from '@angular/fire/firestore';
import { LiftModalComponent } from './subcomponents/lift-modal/lift-modal.component';
import { CardioModalComponent } from './subcomponents/cardio-modal/cardio-modal.component';
import { SportModalComponent } from './subcomponents/sport-modal/sport-modal.component';
import { UserInterface } from '../../models/user.interface';
import { UserService } from '../../services/user-interface.service';
import { TimeUtilsService } from '../../services/time-utils.service';
import { LiftEntry, CardioEntry, SportEntry } from './user-fitness-tracker.models';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-user-fitness-tracker',
  standalone: true,
  imports: [LiftModalComponent, CardioModalComponent, SportModalComponent, CommonModule, MatIconModule],
  templateUrl: './user-fitness-tracker.component.html',
  styleUrls: ['./user-fitness-tracker.component.scss']
})
export class UserFitnessTrackerComponent implements OnInit, OnDestroy {
  dialog = inject(MatDialog);
  userInterface: UserInterface | null | undefined;

  userService = inject(UserService);
  firestore = inject(Firestore);
  timeUtils = inject(TimeUtilsService);

  currentDate = this.timeUtils.today();
  liftEntries: LiftEntry[] = [];
  cardioEntries: CardioEntry[] = [];
  sportEntries: SportEntry[] = [];

  private unsubscribeSnapshot: Unsubscribe | undefined;

  async ngOnInit(): Promise<void> {
    this.userInterface = this.userService.getUserInterface();
    if (this.userInterface) {
      this.getDataForCurrentDate();
    }
  }

  ngOnDestroy(): void {
    // Call the unsubscribe function when the component is destroyed
    if (this.unsubscribeSnapshot) {
      this.unsubscribeSnapshot();
    }
  }

  getDataForCurrentDate() {
    if (this.userInterface) {
      const docRef = doc(this.firestore, `user-fitness-tracker/${this.userInterface.uid}/activities/${this.currentDate}`);
      
      // Listen to real-time updates and store the unsubscribe function
      this.unsubscribeSnapshot = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();

          this.liftEntries = data['lift'] || [];
          this.cardioEntries = data['cardio'] || [];
          this.sportEntries = data['sport'] || [];

          console.log('Lift Entries:', this.liftEntries);
          console.log('Cardio Entries:', this.cardioEntries);
          console.log('Sport Entries:', this.sportEntries);
        } else {
          console.log('No data found for the current date.');
        }
      });
    }
  }

  async saveDataToFirebase(data: Partial<{ lift: LiftEntry[], cardio: CardioEntry[], sport: SportEntry[] }>) {
    if (this.userInterface) {
      const docRef = doc(this.firestore, `user-fitness-tracker/${this.userInterface.uid}/activities/${this.currentDate}`);

      const updates: { lift?: unknown, cardio?: unknown, sport?: unknown } = {};

      if (data.lift) {
        updates.lift = arrayUnion(...data.lift);
      }

      if (data.cardio) {
        updates.cardio = arrayUnion(...data.cardio);
      }

      if (data.sport) {
        updates.sport = arrayUnion(...data.sport);
      }

      await setDoc(docRef, updates, { merge: true });
    }
  }

  modalLift() {
    const dialogRef = this.dialog.open(LiftModalComponent, { width: '350px', height: 'auto' });
    dialogRef.afterClosed().subscribe((result: LiftEntry) => {
      if (result) {
        this.saveDataToFirebase({ lift: [result] });
      }
    });
  }

  modalCardio() {
    const dialogRef = this.dialog.open(CardioModalComponent, { width: '350px', height: 'auto' });
    dialogRef.afterClosed().subscribe((result: CardioEntry) => {
      if (result) {
        this.saveDataToFirebase({ cardio: [result] });
      }
    });
  }

  modalSport() {
    const dialogRef = this.dialog.open(SportModalComponent, { width: '350px', height: 'auto' });
    dialogRef.afterClosed().subscribe((result: SportEntry) => {
      if (result) {
        this.saveDataToFirebase({ sport: [result] });
      }
    });
  }

  formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60); // Calculate the whole hours
    const remainingMinutes = minutes % 60; // Calculate the remaining minutes
  
    // Build the readable format
    const hoursPart = hours > 0 ? `${hours} ${hours === 1 ? 'hour' : 'hours'}` : '';
    const minutesPart = remainingMinutes > 0 ? `${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}` : '';
  
    // Join the parts with a comma if both are present
    return [hoursPart, minutesPart].filter(Boolean).join(', ');
  }
  

  calculatePace(cardioTime: number, cardioDistance: number): string {
    if (cardioDistance === 0) return "Invalid distance";

    const totalMinutes = cardioTime / cardioDistance;
    const minutes = Math.floor(totalMinutes); // Get the whole minutes part
    const seconds = Math.round((totalMinutes - minutes) * 60); // Convert the fractional part to seconds

    // Ensure seconds are always two digits (e.g., "03" instead of "3")
    const secondsFormatted = seconds < 10 ? `0${seconds}` : seconds;

    return `${minutes}:${secondsFormatted} average pace`;
  }
}


