<div class="container" style="background-color: var(--background-color); height: 100%; width: 248px; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 16px;">
  <h1 mat-dialog-title style="color: var(--brand-color);">Confirm Delete</h1>
  <div mat-dialog-content style="color: lightcoral;">
    <p>Are you sure you want to delete this challenge?</p>
  </div>
  <div mat-dialog-actions style="color: var(--brand-color);">
    <button mat-raised-button (click)="onCancel()">Cancel</button>
    <button mat-flat-button (click)="onConfirm()">Delete</button>
  </div>  
</div>
