import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-challenge-form-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule
  ],
  template: `
    <div class="container" style="background-color: var(--background-color); height: 100%; width: 248px; display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 16px;">
    <h1 mat-dialog-title>Add Challenge</h1>
    <div mat-dialog-content>
      <form [formGroup]="challengeForm" (ngSubmit)="onSubmit()">
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input matInput formControlName="challenge_title">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Type</mat-label>
          <mat-select formControlName="challenge_type">
            <mat-option *ngFor="let type of challengeTypes" [value]="type">{{ type }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button type="submit" color="primary" style="border: 3px solid var(--border-color); color: var(--text-color)"[disabled]="!challengeForm.valid">Add</button>
      </form>
    </div>
  </div>
  `,
  styles: [`
    mat-form-field {
      width: 100%;
    }
  `]
})
export class ChallengeFormDialogComponent {
  challengeForm: FormGroup;
  challengeTypes = ['fitness', 'focus', 'social', 'skill', 'nutrition', 'chore', 'mindfulness', 'discipline'];

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChallengeFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { challengeDifficulty: 'easy' | 'medium' | 'hard' }
  ) {
    this.challengeForm = this.fb.group({
      challenge_title: ['', [Validators.required, Validators.minLength(5)]],
      challenge_type: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.challengeForm.valid) {
      this.dialogRef.close(this.challengeForm.value);
    }
  }
}


