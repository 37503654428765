<div class="challenge-holding-container" style="margin-bottom: 10px;">
  <mat-card-title style="margin-bottom: 10px; font-weight: bold;">Personal Daily Challenges</mat-card-title> 
  <mat-card *ngFor="let challenge of challenges" class="challenge-card">
    <div class="color-bar" [ngClass]="{
      'easy': challenge.challenge_difficulty === 'easy',
      'medium': challenge.challenge_difficulty === 'medium',
      'hard': challenge.challenge_difficulty === 'hard'
    }"></div>


    
    <div class="challenge-content">
      @if (challenge.challenge_title){
        <div class="challenge-info">
          <p class="challenge-title">{{ challenge.challenge_title }}</p>
          <p class="challenge-points">
            {{ challenge.challenge_difficulty === 'hard' ? '3 Points' : challenge.challenge_difficulty === 'medium' ? '2 Points' : '1 Point' }}
          </p>
        </div>
        <div class="challenge-actions">
          <mat-icon (click)="openDeleteConfirmationDialog(challenge)">delete</mat-icon>
        </div>
      } @else {
        <div class="challenge-info">
          <p class="challenge-title" style="color: lightsalmon;">(Open {{ capitalizeFirstLetter(challenge.challenge_difficulty) }} Challenge)</p>
          <p class="challenge-points">
            {{ challenge.challenge_difficulty === 'hard' ? '3 Points' : challenge.challenge_difficulty === 'medium' ? '2 Points' : '1 Point' }}
          </p>
        </div>
        
        <div class="challenge-actions">
          <mat-icon (click)="openAddChallengeDialog(challenge.challenge_difficulty)" style="color: lightgreen">add_circle</mat-icon>
        </div>
      }
    </div>
  </mat-card>
</div>

