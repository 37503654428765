export interface UserTitle {
    avatarURL: string;
    avatarUsername: string; 
    avatarCountry: string;
    avatarFlagURL: string;
    avatarLevel: number;
    avatarExperience: number;
    avatarStreak: number;
    avatarCrownURL: string;
    avatarCrownColor: string;
    avatarRank: '' | 'Moderator' | 'Administrator' | 'Robot' | 'Noobie' | 'Initiate' | 'Member' | 'Leader' | 'General' | 'Competitor' | 'Challenger' | 'Champion';
    avatarNextRank: '' | 'Moderator' | 'Administrator' | 'Robot' | 'Noobie' | 'Initiate' | 'Member' | 'Leader' | 'General' | 'Competitor' | 'Challenger' | 'Champion';
    avatarNextCrownColor: string;
    avatarNextCrownURL: string;
    avatarNextLevelReq: number;
}

export const defaultUserTitle: UserTitle = {
    avatarURL: '',
    avatarUsername: '',
    avatarCountry: '',
    avatarFlagURL: '',
    avatarLevel: 0,
    avatarExperience: 0,
    avatarStreak: 0,
    avatarCrownURL: '',
    avatarCrownColor: '',
    avatarRank: '',
    avatarNextRank: '',
    avatarNextCrownColor: '',
    avatarNextCrownURL: '',
    avatarNextLevelReq: 0,
  };
  