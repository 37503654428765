import { Routes } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ChallengePageComponent } from './pages/challenge-page/challenge-page.component';
import { TestComponent } from './pages/test/test.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { AuthGuard } from './services/auth.guard';
import { RedirectGuard } from './services/redirect.guard';
import { ProfileRedirectComponent } from './pages/profile-page/service/profile-redirect.component';
import { CommunityPageComponent } from './pages/community-page/community-page.component';
import { LeaderboardPageComponent } from './pages/leaderboard-page/leaderboard-page.component';
import { DailyQuizPageComponent } from './pages/daily-quiz-page/daily-quiz-page.component';
import { Area52Component } from './pages/area-52/area-52.component';
import { UserPersonalChallengesPageComponent } from './pages/user-personal-challenges-page/user-personal-challenges-page.component';
import { UserCommunityChallengesPageComponent } from './pages/user-community-challenges-page/user-community-challenges-page.component';
import { StatsPageComponent } from './pages/stats-page/stats-page.component';
import { FitnessTrackerPageComponent } from './pages/fitness-tracker-page/fitness-tracker-page.component';

export const routes: Routes = [// Handle undefined routes
  { path: '', component: LandingPageComponent, canActivate: [RedirectGuard] },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuard] },
  // { path: 'home', component: FitnessTrackerPageComponent, canActivate: [AuthGuard] },
  { path: 'challenges', component: ChallengePageComponent, canActivate: [AuthGuard] },
  { path: 'test', component: TestComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileRedirectComponent, canActivate: [AuthGuard] },
  { path: 'profile/:username', component: ProfilePageComponent, canActivate: [AuthGuard] },
  { path: 'statistics', component: StatsPageComponent, canActivate: [AuthGuard] },
  { path: 'community', component: CommunityPageComponent, canActivate: [AuthGuard] },
  { path: 'quiz', component: DailyQuizPageComponent, canActivate: [AuthGuard] },
  { path: 'user-personal-challenges', component: UserPersonalChallengesPageComponent, canActivate: [AuthGuard] },
  { path: 'user-community-challenges', component: UserCommunityChallengesPageComponent, canActivate: [AuthGuard] },
  { path: 'fitness', component: FitnessTrackerPageComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/' }, 
];
