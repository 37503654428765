import { Component, inject, OnInit } from '@angular/core';
import { CountdownClockComponent } from '../countdown-clock/countdown-clock.component';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-community-stats-module',
  standalone: true,
  imports: [CountdownClockComponent, MatIconModule, MatCardModule],
  templateUrl: './community-stats-module.component.html',
  styleUrl: './community-stats-module.component.scss'
})
export class CommunityStatsModuleComponent implements OnInit{

  firestore = inject(Firestore);

  totalChallengesCompleted: number | null = null;
  totalPoints: number | null = null;
  totalUniqueUsers: number | null = null;
  userIds: string[] = [];

  ngOnInit(): void {
    
    this.getCommunityStatistics();
  }

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  async getCommunityStatistics(){
    const currentDate = this.today();
    const communityStatisticsDocRef = doc(this.firestore, `statistics-community-activity/${currentDate}`);
    const communityStatisticsSnapshot = await getDoc(communityStatisticsDocRef);

    if (communityStatisticsSnapshot.exists()){
      const communityStatisticsData = communityStatisticsSnapshot.data();
      this.totalChallengesCompleted = communityStatisticsData['totalChallengesCompleted'];
      this.totalPoints = communityStatisticsData['totalPoints'];
      this.totalUniqueUsers = communityStatisticsData['totalUniqueUsers'];
      this.userIds = communityStatisticsData['userIds'];
    }
  }

}

