

<form [formGroup]="liftInputForm" (ngSubmit)="onSubmit()">
    <h2>Log Your Lift</h2>
  <mat-form-field appearance="fill" >
    <mat-label>Muscle Area</mat-label>
    <mat-select formControlName="liftMuscleArea">
      <mat-option value="Upper Body">Upper Body</mat-option>
      <mat-option value="Lower Body">Lower Body</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="exerciseTypes.length > 0">
    <mat-label>Exercise Type</mat-label>
    <mat-select formControlName="liftExerciseType">
      <mat-option *ngFor="let type of exerciseTypes" [value]="type">{{ type }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="exerciseNames.length > 0">
    <mat-label>Exercise Name</mat-label>
    <mat-select formControlName="liftExerciseName">
      <mat-option *ngFor="let name of exerciseNames" [value]="name">{{ name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="liftInputForm.get('liftExerciseName')?.value">
    <mat-label>Weight (lbs)</mat-label>
    <mat-select formControlName="liftExerciseWeight">
      <mat-option *ngFor="let weight of weightOptions" [value]="weight">
        {{ weight }} lbs
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field appearance="fill" *ngIf="liftInputForm.get('liftExerciseName')?.value">
    <mat-label>Sets</mat-label>
    <mat-select formControlName="liftExerciseSets">
      <mat-option *ngFor="let set of setsOptions" [value]="set">
        {{ set }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field appearance="fill" *ngIf="liftInputForm.get('liftExerciseName')?.value">
    <mat-label>Reps</mat-label>
    <mat-select formControlName="liftExerciseReps">
      <mat-option *ngFor="let rep of repsOptions" [value]="rep">
        {{ rep }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  

  <button mat-raised-button color="primary" type="submit" [disabled]="liftInputForm.invalid" class="disabled-button">Submit</button>
</form>


