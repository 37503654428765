import { Component, inject, OnInit } from '@angular/core';
import { UserService } from '../../services/user-interface.service';
import { UserInterface } from '../../models/user.interface';
import { UserStatisticsComponent } from '../../components/user-statistics/user-statistics.component';


@Component({
  selector: 'app-stats-page',
  standalone: true,
  imports: [UserStatisticsComponent],
  templateUrl: './stats-page.component.html',
  styleUrl: './stats-page.component.scss'
})
export class StatsPageComponent implements OnInit{
  userInterface: UserInterface | null | undefined;
  userService = inject(UserService);

  ngOnInit(): void {
    this.userInterface = this.userService.getUserInterface();

  }

}
