<form [formGroup]="sportInputForm" (ngSubmit)="onSubmit()">
    <!-- Sport Name -->
    <mat-form-field appearance="fill">
      <mat-label>Sport Name</mat-label>
      <mat-select formControlName="sportName">
        <mat-option *ngFor="let name of sportNameList" [value]="name">
          {{ name }}
        </mat-option>
      </mat-select>
    </mat-form-field>   
  
    <!-- Sport Event -->
    <mat-form-field appearance="fill" *ngIf="sportInputForm.get('sportName')?.value">
      <mat-label>Sport Event</mat-label>
      <mat-select formControlName="sportEvent">
        <mat-option *ngFor="let event of sportEventList" [value]="event">
          {{ event }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
    <!-- Sport Time: Hours and Minutes -->
    <div formGroupName="sportTime" *ngIf="sportInputForm.get('sportEvent')?.value">
      <mat-form-field appearance="fill">
        <mat-label>Hours</mat-label>
        <mat-select formControlName="hours">
          <mat-option *ngFor="let hour of sportDurationHourList" [value]="hour">
            {{ hour }} {{ hour === 1 ? 'hour' : 'hours' }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="fill">
        <mat-label>Minutes</mat-label>
        <mat-select formControlName="minutes">
          <mat-option *ngFor="let minute of sportDurationMinutesList" [value]="minute">
            {{ minute }} minutes
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
    <!-- Sport Lessons -->
    <mat-form-field appearance="fill" *ngIf="sportInputForm.get('sportTime.hours')?.value || sportInputForm.get('sportTime.minutes')?.value">
      <mat-label>Sport Lessons</mat-label>
      <input matInput formControlName="sportLessons" placeholder="What did you learn today?">
    </mat-form-field>
  
    <!-- Sport Focus -->
    <mat-form-field appearance="fill" *ngIf="sportInputForm.get('sportTime.hours')?.value || sportInputForm.get('sportTime.minutes')?.value">
      <mat-label>Sport Focus</mat-label>
      <input matInput formControlName="sportFocus" placeholder="What do you need to focus on?">
    </mat-form-field>
  
    <!-- Submit Button -->
    <button mat-raised-button color="primary" type="submit" [disabled]="sportInputForm.invalid" class="disabled-button">
      Submit
    </button>
  </form>
  
