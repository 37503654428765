import { Component, inject, OnInit, ElementRef, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UserInterface } from '../../models/user.interface';
import { UserService } from '../../services/user-interface.service';
import { FormsModule } from '@angular/forms';
import { UserTitle } from '../../models/userTitle.model';
import { Firestore } from '@angular/fire/firestore';
import { arrayUnion, doc, setDoc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-user-chat-interface',
  standalone: true,
  imports: [FormsModule, CommonModule, RouterModule],
  templateUrl: './user-chat-interface.component.html',
  styleUrls: ['./user-chat-interface.component.scss']
})
export class UserChatInterfaceComponent implements OnInit, AfterViewInit {
  userInterface: UserInterface | null | undefined;
  userTitle: UserTitle | undefined;
  chatMessage: string = '';
  authService = inject(AuthService);
  userService = inject(UserService);
  firestore = inject(Firestore);

  @ViewChild('messagebox') private messageBox!: ElementRef;
  private shouldScroll = true;

  chatMessages: any[] = [];

  ngOnInit(): void {
    this.userInterface = this.userService.getUserInterface();

    if (this.userInterface) {
        this.userTitle = this.userService.classifyUser(this.userInterface);
    }

    this.loadInitialMessages();
    this.listenForMessages();
  }

  ngAfterViewInit(): void {
    this.scrollToBottom(true); // Scroll to bottom on initial load
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    // Check if the user is near the bottom of the chat box
    const threshold = 100; // Adjust this value as needed
    const position = this.messageBox.nativeElement.scrollTop + this.messageBox.nativeElement.offsetHeight;
    const height = this.messageBox.nativeElement.scrollHeight;
    
    this.shouldScroll = position > height - threshold;
  }

  scrollToBottom(forceScroll: boolean = false): void {
    try {
      if (this.shouldScroll || forceScroll) {
        setTimeout(() => {
          if (this.messageBox) {
            this.messageBox.nativeElement.scrollTop = this.messageBox.nativeElement.scrollHeight;
          }
        }, 0); // Short timeout to allow DOM update
      }
    } catch (err) {
      console.log('Scroll failed:', err);
    }
  }

  async loadInitialMessages(): Promise<void> {
    const currentDate = this.today();
    const docRefUserDailyMessages = doc(this.firestore, `user-daily-messages/${currentDate}`);

    const docSnapshot = await getDoc(docRefUserDailyMessages);
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      const messages = (data['messages'] || []).slice(-25).map((message: any) => ({
        ...message,
        timestamp: message.timestamp.toDate()
      }));
      this.updateChatUI(messages);
    } else {
      this.updateChatUI([]);
    }
  }

  listenForMessages(): void {
    const currentDate = this.today();
    const docRefUserDailyMessages = doc(this.firestore, `user-daily-messages/${currentDate}`);

    onSnapshot(docRefUserDailyMessages, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const messages = (data['messages'] || []).slice(-25).map((message: any) => ({
          ...message,
          timestamp: message.timestamp.toDate()
        }));
        this.updateChatUI(messages);
      } else {
        console.log("No messages found for today.");
        this.updateChatUI([]);
      }
    });
  }

  updateChatUI(messages: any[]): void {
    this.chatMessages = messages;
    this.scrollToBottom(); // Only scroll if user is at the bottom
  }

  async sendChatMessage(message: string) {
    if (this.chatMessage.length > 0 && this.chatMessage.length <= 140) {
      const currentDate = this.today();
      const docRefUserDailyMessages = doc(this.firestore, `user-daily-messages/${currentDate}`);
      
      const basicChatInput = {
        uid: this.userInterface?.uid,
        avatarUsername: this.userInterface?.username,
        avatarLevel: this.userInterface?.userLevel,
        avatarFlagURL: this.userTitle?.avatarFlagURL,
        avatarURL: this.userInterface?.userAvatarURL,
        avatarCrownColor: this.userTitle?.avatarCrownColor,
        avatarCrownURL: this.userTitle?.avatarCrownURL,
        message: this.chatMessage,
        timestamp: new Date()
      }

      try {
        await updateDoc(docRefUserDailyMessages, {
          messages: arrayUnion(basicChatInput)
        });
      } catch (error) {
        await setDoc(docRefUserDailyMessages, {
          messages: [basicChatInput]
        });
      };

      this.chatMessage = '';
      this.scrollToBottom(true); // Force scroll after sending a message
    } else {
      console.log("Message is empty or exceeds character limit.");
    }
  }

  onMessageInput(): void {
    if (this.chatMessage.length > 140) {
      this.chatMessage = this.chatMessage.substring(0, 140); // Ensure the message doesn't exceed 140 characters
    }
  }

  today(): string {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

