import { Component, inject, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { QuizDataService } from './daily-quiz-timeseries/quiz-timeseries-data.service';
import { TimeUtilsService } from '../../services/time-utils.service';
import { UserInterface } from '../../models/user.interface';
import { DailyQuizTimeseriesComponent } from './daily-quiz-timeseries/daily-quiz-timeseries.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { DailyQuizPieComponent } from './daily-quiz-pie/daily-quiz-pie.component';

@Component({
  selector: 'app-user-statistics',
  standalone: true,
  imports: [DailyQuizTimeseriesComponent, MatProgressSpinnerModule, CommonModule, DailyQuizPieComponent],
  templateUrl: './user-statistics.component.html',
  styleUrl: './user-statistics.component.scss'
})
export class UserStatisticsComponent {
  quizDataService = inject(QuizDataService);
  timeUtilsService = inject(TimeUtilsService);
  changeDetectorRef = inject(ChangeDetectorRef);

  @Input() userInterface: UserInterface | null | undefined;

  userQuizStats: {
    quizDates: string[],
    morningFeelings: string [],
    morningMotivation: string [],
    morningWakeUpTimes: string[],
    morningBedTimes: string[],
    bedTimeMinutes: number[],
    wakeUpMinutes: number[],
    sleepTimeMinutes: number[],
    awakeTimeMinutes: number[],
    avgBedTimeMinutes?: number,
    avgWakeUpTimeMinutes?: number,
    avgSleepTimeMinutes?: number,
    avgAwakeTimeMinutes?: number,
  } = {
    quizDates: [],
    morningWakeUpTimes: [],
    morningFeelings: [],
    morningMotivation: [],
    morningBedTimes: [],
    bedTimeMinutes: [],
    wakeUpMinutes: [],
    sleepTimeMinutes: [],
    awakeTimeMinutes: []
  };
  
  filterTimeRange: 7 | 14 | 21 | 30 = 7;
  dataReady = false;
  currentDate = new Date();
  sevenDaysAgo = this.timeUtilsService.getDaysAgo(this.currentDate, this.filterTimeRange);
  
  @ViewChild(DailyQuizTimeseriesComponent) timeseriesComponent!: DailyQuizTimeseriesComponent;

  ngOnInit(): void {
    this.updateDateRange();
    this.loadUserQuizData();
  }

  selectTimeRange(days: 7 | 14 | 21 | 30) {
    this.filterTimeRange = days;
    this.updateDateRange();  // Update the date range when the filter changes
    this.destroyCharts(); // Destroy the chart and set dataReady to false
    this.loadUserQuizData();  // Reload the quiz data with the new time range
  }

  updateDateRange() {
    this.currentDate = new Date();
    this.sevenDaysAgo = this.timeUtilsService.getDaysAgo(this.currentDate, this.filterTimeRange);
  }

  formatDateWithSuffix(dateStr: string): string {
    const [year, month, day] = dateStr.split('-').map(Number);
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[month - 1];
    const daySuffix = this.getDaySuffix(day);

    return `${monthName} ${day}${daySuffix}, ${year}`;
  }

  getDaySuffix(day: number): string {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
  }

  async loadUserQuizData() {
    if (!this.userInterface) return;

    const daysInRange = this.timeUtilsService.getDaysInRange(this.sevenDaysAgo, this.currentDate);

    const quizzes = await this.quizDataService.getUserQuizzes(this.userInterface, daysInRange);
    
    this.userQuizStats.quizDates = quizzes.map(q => q.date);
    this.userQuizStats.morningWakeUpTimes = quizzes.map(q => q.morningWakeUp);
    this.userQuizStats.morningBedTimes = quizzes.map(q => q.morningBedTime);
    this.userQuizStats.morningFeelings = quizzes.map(q => q.morningFeeling);
    this.userQuizStats.morningMotivation = quizzes.map(q => q.morningMotivation);

    console.log(this.userQuizStats);
    this.processUserQuizStats();
    this.dataReady = true;
    
  }

  processUserQuizStats() {
    const shiftedBedTimes = this.userQuizStats.morningBedTimes.slice(1).concat('N/A');
    this.userQuizStats.bedTimeMinutes = shiftedBedTimes.map(time => this.timeUtilsService.timeToMinutes(time));
    this.userQuizStats.wakeUpMinutes = this.userQuizStats.morningWakeUpTimes.map(time => this.timeUtilsService.timeToMinutes(time));
    this.userQuizStats.bedTimeMinutes = this.userQuizStats.bedTimeMinutes.map(minutes => minutes < 720 ? minutes + 1440 : minutes);
    this.userQuizStats.sleepTimeMinutes = this.userQuizStats.wakeUpMinutes.map((wakeUp, index) => wakeUp + (1440 - this.userQuizStats.bedTimeMinutes[index]));
    this.userQuizStats.awakeTimeMinutes = this.userQuizStats.sleepTimeMinutes.map(sleep => 1440 - sleep);

    this.userQuizStats.avgBedTimeMinutes = this.timeUtilsService.calculateAverage(this.userQuizStats.bedTimeMinutes);
    this.userQuizStats.avgWakeUpTimeMinutes = this.timeUtilsService.calculateAverage(this.userQuizStats.wakeUpMinutes);
    this.userQuizStats.avgSleepTimeMinutes = this.timeUtilsService.calculateAverage(this.userQuizStats.sleepTimeMinutes);
    this.userQuizStats.avgAwakeTimeMinutes = this.timeUtilsService.calculateAverage(this.userQuizStats.awakeTimeMinutes);

    console.log("Average Bed Time:", this.timeUtilsService.minutesToTimeStr(this.userQuizStats.avgBedTimeMinutes));
    console.log("Average Wake Up Time:", this.timeUtilsService.minutesToTimeStr(this.userQuizStats.avgWakeUpTimeMinutes));
    console.log("Average Sleep Time:", this.timeUtilsService.minutesToDurationStr(this.userQuizStats.avgSleepTimeMinutes));
    console.log("Average Awake Time:", this.timeUtilsService.minutesToDurationStr(this.userQuizStats.avgAwakeTimeMinutes));
  }

  destroyCharts() {
    if (this.timeseriesComponent) {
      this.timeseriesComponent.destroyChart();
    }
    this.dataReady = false; // Set dataReady to false to indicate that data is being refetched
    this.loadUserQuizData(); // Refetch the data and rebuild the chart
  }
}


