<div class="fitness-action-bar">
    <div class="fitness-action" (click)="modalLift()">
      <h1>Lift</h1>
    </div>
    <div class="fitness-action" (click)="modalCardio()">
      <h1>Run</h1>
    </div>
    <div class="fitness-action" (click)="modalSport()">
      <h1>Sport</h1>
    </div>
  </div>
  
  <div class="fitness-logger">
    <!-- Lift Activities Container -->
    <div class="activity-container" *ngIf="liftEntries.length > 0">
      <p class="activity-label">Lifting Activites</p>
      <div class="activity-cell" *ngFor="let lift of liftEntries">
        <h1>{{ lift.liftExerciseName }}</h1>

        <div class="activity-row">
          <h2> {{ lift.muscleGroup }}</h2>
          <mat-icon style="font-size: 10px; text-align: center; margin-top: 15px; color: var(--brand-color);">circle</mat-icon>
          <h2>{{ lift.liftExerciseWeight }} lbs</h2>
        </div>
        
        <h3>{{ lift.liftExerciseSets }} sets of {{ lift.liftExerciseReps }}</h3>
        <h4>{{ lift.liftExerciseSets * lift.liftExerciseReps }} total repititions</h4>
      </div>
    </div>
  
    <!-- Cardio Activities Container -->
    <div class="activity-container" *ngIf="cardioEntries.length > 0">
      <p class="activity-label">Cardio Activites</p>
      <div class="activity-cell" *ngFor="let cardio of cardioEntries">
        <h1>{{ cardio.cardioLocation }} {{ cardio.cardioName }}</h1>


        <h2>{{ cardio.cardioDistance }} miles</h2>
        @if (cardio.cardioHours > 0) {
          <h3>{{cardio.cardioHours}} hours, {{cardio.cardioMinutes}} minutes</h3>
        } @else {
          <h3>{{cardio.cardioMinutes}} minutes</h3>
        }
        
        <h2>{{ calculatePace(cardio.cardioTime, cardio.cardioDistance) }}</h2>
      </div>
    </div>
  
    <!-- Sport Activities Container -->
    <div class="activity-container" *ngIf="sportEntries.length > 0">
      <p class="activity-label">Sport Activites</p>
      <div class="activity-cell" *ngFor="let sport of sportEntries">
        <h1>{{ sport.sportName }}</h1>
        <h2> {{ sport.sportEvent }} </h2>
        <h3> {{ formatMinutes(sport.sportTime) }}</h3>
        <h4 style="color: var(--secondary-color); border-bottom: 2px solid var(--border-color)"> What did you focus on? </h4>
        <p>{{ sport.sportFocus }}</p>
        
        <h4 style="color: var(--secondary-color); border-bottom: 2px solid var(--border-color)"> What were the takeaways? </h4>
        <p>{{ sport.sportLessons }}</p>
        
      </div>
    </div>
  
    <!-- Fallback Message -->
    <ng-container *ngIf="!liftEntries.length && !cardioEntries.length && !sportEntries.length">
      <div class="no-data-container">
        <p>No activities logged for today.</p>
      </div>
    </ng-container>
  </div>
  