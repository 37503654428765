import { Component, inject } from '@angular/core';
import { UserInterface } from '../../models/user.interface';
import { AuthService } from '../../services/auth.service';
import { ChallengeModuleComponent } from '../../components/challenge-module/challenge-module.component';
import { ChallengeModuleInterface } from '../../models/challengeModule.interface';


@Component({
  selector: 'app-challenge-page',
  standalone: true,
  imports: [ChallengeModuleComponent],
  templateUrl: './challenge-page.component.html',
  styleUrl: './challenge-page.component.scss'
})
export class ChallengePageComponent {
  userInterface: UserInterface | null | undefined;
  authService = inject(AuthService);
  
  ngOnInit(): void {
    this.userInterface = this.authService.currentUserSig();
  }

  userDailyChallenges: ChallengeModuleInterface[] = [
    {
      challenge_id: 1,
      challenge_difficulty: 'easy',
      challenge_type: 'fitness',
      challenge_title: 'Perform 10 pushups.',
      challenge_status: false,
    },
    {
      challenge_id: 2,
      challenge_difficulty: 'medium',
      challenge_type: 'focus',
      challenge_title: 'Read 10 pages.',
      challenge_status: false,
    },
    {
      challenge_id: 3,
      challenge_difficulty: 'hard',
      challenge_type: 'skill',
      challenge_title: 'Practice a skill for one hour.',
      challenge_status: false,
    }
  ];

  communityDailyChallenges: ChallengeModuleInterface[] = [
    {
      challenge_id: 1,
      challenge_difficulty: 'easy',
      challenge_type: 'nutrition',
      challenge_title: 'Drink a glass of water',
      challenge_status: false,
    },
    {
      challenge_id: 2,
      challenge_difficulty: 'medium',
      challenge_type: 'chore',
      challenge_title: 'Organize a personal area of yours',
      challenge_status: false,
    },
    {
      challenge_id: 3,
      challenge_difficulty: 'hard',
      challenge_type: 'mindfulness',
      challenge_title: 'Closed eye breathing, 5 minutes',
      challenge_status: false,
    }
  ];


}
