import { Component, Input, OnInit, NgModule } from '@angular/core';
import { UserInterface } from '../../models/user.interface';
import { UserClassificationService } from '../../services/user-classification.service';
import { UserTitle } from '../../models/userTitle.model';
import { SharedMaterialModule } from '../../shared/material-components/material-module.module';
import { profileDoodleman } from '../../models/avatars/doodleman.profile';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-forum-profile',
  standalone: true,
  imports: [SharedMaterialModule, CommonModule],
  templateUrl: './user-forum-profile.component.html',
  styleUrl: './user-forum-profile.component.scss'
})
export class UserForumProfileComponent  {
  @Input() userForumTitle?: UserTitle | null | undefined;
 
  displayCrown: boolean = true;
  displayColor: boolean = true;
  




  helperDisplayFunction() {
    if (this.userForumTitle) {
      if (this.userForumTitle.avatarCrownURL === '') {
        this.displayCrown = !this.displayCrown;
        this.displayColor = !this.displayColor;
        console.log(this.userForumTitle);
      }
    }
  }

 


  
  
}
