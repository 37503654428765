
import { Component, inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedMaterialModule } from '../../../../shared/material-components/material-module.module';
import { CardioEntry } from '../../user-fitness-tracker.models';

@Component({
  selector: 'app-cardio-modal',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, SharedMaterialModule],
  templateUrl: './cardio-modal.component.html',
  styleUrl: './cardio-modal.component.scss'
})
export class CardioModalComponent implements OnInit{
  dialogRef = inject(MatDialogRef);
  close () {
    this.dialogRef.close();
  }

  formBuilder = inject(FormBuilder);
  cardioInputForm = this.formBuilder.group({
    cardioName: [null, Validators.required],
    cardioLocation: [null, Validators.required],
    cardioDistance: [null, [Validators.required, Validators.min(0)]],
    cardioTime: this.formBuilder.group({
      hours: [0, []],
      minutes: [0, [Validators.required, Validators.min(0), Validators.max(59)]]
    })
  });

  distanceOptions: number[] = Array.from({length: 150}, (_, i) => (i+1) * 0.25);
  hourOptions: number[] = Array.from({length: 10}, (_,i) => (i) * 1);
  minuteOptions: number[] = Array.from({length: 60}, (_, i) => (i+1) * 1);

  cardioNameList: string [] = ['Walk', 'Run', 'Bike','Stair Stepper', 'Eliptical'];
  cardioLocationList: string[] = ['Indoor', 'Outdoor'];

  ngOnInit(): void {
    this.cardioInputForm.get('cardio')?.valueChanges.subscribe    
  }

  onSubmit() {
    if (this.cardioInputForm.valid) {
      const formValue = this.cardioInputForm.value;
  
      const cardioName = formValue.cardioName ?? ''; // default to empty string if null or undefined
      const cardioLocation = formValue.cardioLocation ?? '';
      const cardioDistance = formValue.cardioDistance ?? 0; // default to 0 if null or undefined
      const cardioHours = formValue.cardioTime?.hours ?? 0;
      const cardioMinutes = formValue.cardioTime?.minutes ?? 0;
      const totalMinutes = (cardioHours * 60) + cardioMinutes;
  
      const submissionData: CardioEntry = {
        cardioName,
        cardioLocation,
        cardioDistance,
        cardioHours,
        cardioMinutes,
        cardioTime: totalMinutes
      };
  
      console.log('Cardio Form Submitted!', submissionData);
      this.dialogRef.close(submissionData);
    }
  }
  
  
}
