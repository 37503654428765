import { Component, computed, signal, NgModule, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';

import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-landing-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatInputModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule],
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {
  authService = inject(AuthService);

  constructor(private router: Router) {}

  navigateToAuth() {
    this.router.navigate(['/auth']);
  }


  submitLogout() {
    console.log('logout');
    this.authService.logout();
  }

  signInWithGoogle() {
    this.authService.signInWithGoogle().subscribe({
      next: () => {
        this.router.navigateByUrl('/home');
      },
      error: (error) => {
        console.error('Google Sign-In error:', error);
      }
    });
  }

  heroImage = '/assets/heroImage.png';
  mainLogo = '/assets/mainLogo.png';

  iconCompete = '/assets/icons/iconCompete.png'
  iconSupport = '/assets/icons/iconSupport_v2.png'
  iconTrophy = '/assets/icons/iconTrophy.png'


  features = [
    {
      icon: this.iconCompete,
      title: 'Challenge & Compete',
      description: 'Build life-changing habits with friends worldwide.'
    },
    {
      icon: this.iconTrophy,
      title: 'Rewarding Experience',
      description: 'Feel great, achieve goals, and have fun!'
    },
    {
      icon: this.iconSupport,
      title: 'Supportive Community',
      description: 'Ditch negativity, find motiviation and support.'
    }
  ];

}
