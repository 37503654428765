<mat-toolbar>
    
    <img [src]="mainLogo" alt="Main logo" height="35px" style="margin-top: 0;" [class.active]="isActive('/')" [routerLink]="['/home']">
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/settings']">
        <mat-icon>settings</mat-icon>
        <span>Settings</span>
      </button>
      <button mat-menu-item [routerLink]="['/profile']">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
      </button>
      <button mat-menu-item (click)="submitLogout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
    <button mat-icon-button [routerLink]="['/profile']">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-toolbar>
  