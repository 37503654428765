

  
<div class="hero">

  <div class="col" style="gap: 0px; margin: 0; padding: 8px; color: red; border: 3px solid red; border-radius: 16px; max-width: 300px; font-weight: bold;">
    <h1>ALPHA v0.0.1</h1>
  </div>
    <img [src]="heroImage" alt="Hero image" width="500px;" style="margin: 10px 0; margin-right: 50px;"/>
    <img [src]="mainLogo" alt="Main logo" style="margin-top: 0;" >
    <div class="header" style="font-size: 16px; font-weight: bold;"> A journey to impact the world,</div>
    <div class="header" style="font-size: 16px; font-weight: bold;"> One <span class="highlight-text">Challenge</span> at a Time.</div>
    <span style="height: 25px;"></span>
    <div class="header" style="font-size: 14px; font-weight: bold;"> Connecting challenge-seekers from across the globe,</div>
    <div class="header" style="font-size: 14px; font-weight: bold;"> Deviate from <span class="highlight-text">Average,   </span> Unleash your inner <span class="highlight-text">Champion</span></div>
    <p></p>
    <button mat-flat-button color="primary" (click)="signInWithGoogle()">Get Started</button>
</div>

  
  
<div class="features"> 
  <mat-card class="feature-card" *ngFor="let feature of features">
    <mat-card-header class="feature-card-header">
      <img [src]="feature.icon" alt="{{ feature.title }}" class="feature-icon"/>
      <mat-card-title style="font-size: 20px; color: var(--secondary-color); font-weight: bold;">{{ feature.title }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>{{ feature.description }}</p>
    </mat-card-content>
  </mat-card>
</div>
  
  <footer>
    <p>Where <span class="highlight-text">Imagination</span> Becomes <span class="highlight-text">Reality</span>.</p>
  <!-- <p>&copy; 2024 Sandbox Computing. All rights reserved.</p> -->
  </footer>