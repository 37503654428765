<div class="col" style="border: 2px solid var(--border-color); border-radius: 16px; width: 350px; padding: 8px; height: 300px;">
    @if (isLoading){
        <mat-spinner style="height: 50%"></mat-spinner>
    } @else {
        <app-user-forum-profile [userForumTitle]="this.userForumTitle"></app-user-forum-profile>

        <div class="stats-header">
            <span class="stats-title">Weekly Progress</span>
            <span class="stats-subtitle">{{ this.formatDateWithSuffix(startDate) }} - {{ this.formatDateWithSuffix(endDate) }}</span>
        </div>

        <div class="row" style="margin-top: 10px;">
            <div class="stat-box">
                <div class="stat-box-header">
                    <span>Points</span>
                </div>
                <span style="font-weight: bold">{{ UserWeeklyStats.totalPointsEarned }}</span>
            </div>
            <div class="stat-box">
                <div class="stat-box-header">
                    <span>Challenges</span>
                </div>
                <span style="font-weight: bold">{{ UserWeeklyStats.totalChallengesCompleted }} / {{ UserWeeklyStats.totalChallengesAvailable }}</span>
            </div>
            <div class="stat-box">
                <div class="stat-box-header">
                    <span>Win Percent</span>
                </div>
                <span style="font-weight: bold">{{ this.helperRoundingFunction(UserWeeklyStats.winPercent) }}%</span>
            </div>
        </div>

        <div class="row" style="margin-top: 10px; width: 100%; height: 100%; max-width: 300px;">
            <span class="avatar-level">{{this.userInterface?.userLevel}}</span>
            <mat-progress-bar [value]="(this.userInterface?.userExperience ?? 0) * 100" [ngClass]="{'custom-progress-bar': true}">
            </mat-progress-bar>
            <span class="avatar-level">{{(this.userInterface?.userLevel ?? 0) + 1}} </span>
        </div>

        <div class="row">
            <img [src]="this.userForumTitle?.avatarNextCrownURL" alt="Next Level Crown">
            <span class="stats-subtitle" style="font-weight:bold;" [ngStyle]="{'color': this.userForumTitle?.avatarNextCrownColor || 'white'}">
                {{(this.userForumTitle?.avatarNextLevelReq ?? 0) - (this.userForumTitle?.avatarLevel ?? 0)}} levels until rank up!
            </span>
        </div>
    }
</div>

