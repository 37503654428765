<div class="challenge-holding-container" style="margin-bottom: 10px;">
  <mat-card-title style="margin-bottom: 10px; font-weight: bold;">{{ challengeModuleTitle }}</mat-card-title> 
  @if (hasNullTitle()){
    Please submit your personal challenges!
  } @else {
    <mat-card *ngFor="let challenge of challenges" class="challenge-card">
      <div class="color-bar" [ngClass]="{
        'easy': challenge.challenge_difficulty === 'easy',
        'medium': challenge.challenge_difficulty === 'medium',
        'hard': challenge.challenge_difficulty === 'hard'
      }"></div>
      
      <div class="challenge-content">
        <div class="challenge-info">
          <p class="challenge-title">{{ challenge.challenge_title }}</p>
          <p class="challenge-subtitle">{{ capitalizeFirstLetter(challenge.challenge_difficulty) }}</p>
          <p class="challenge-points">
            @if (challenge.challenge_difficulty === 'hard'){
              3 Points
            } @else if (challenge.challenge_difficulty === 'medium'){
              2 Points
            } @else {
              1 Point
            }
          </p>
        </div>
        <div class="challenge-actions">
          @if (!challenge.challenge_status){
            <div class="challenge-toggle">
              <mat-slide-toggle class="custom-slide-toggle"
                color="primary"
                [ngModel]="localChallengeState[challenge.challenge_id]"
                (ngModelChange)="onToggleChange(challenge.challenge_id, $event)">
              </mat-slide-toggle>
            </div>
            <button mat-raised-button color="primary" style="background-color: var(--secondary-color);" (click)="submitChallenge(challenge)">Submit</button>
          }
        </div>
        <div class="challenge-status">
          @if (challenge.challenge_status){
            <mat-icon style="color: lightgreen">check_circle</mat-icon>
          } @else if (!challenge.challenge_status){
            <mat-icon style="color: lightcoral">check_circle</mat-icon>
          }
        </div>
      </div>
    </mat-card>
  }
</div>

