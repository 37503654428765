import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { LiftEntry } from '../../user-fitness-tracker.models';

@Component({
  selector: 'app-lift-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSliderModule
  ],
  templateUrl: './lift-modal.component.html',
  styleUrls: ['./lift-modal.component.scss']
})
export class LiftModalComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  dialogRef = inject(MatDialogRef);

  liftInputForm = this.formBuilder.group({
    liftMuscleArea: [null, Validators.required],
    liftExerciseType: [null, Validators.required],
    liftExerciseName: [null, Validators.required],
    liftExerciseWeight: [null, Validators.required],
    liftExerciseSets: [null, Validators.required],
    liftExerciseReps: [null, Validators.required] // Added rep count field
  });

  exerciseTypes: string[] = [];
  exerciseNames: string[] = [];
  showWeightInput = false;
  showSetsInput = false;
  showRepsInput = false; // Added flag for showing reps input

  weightOptions: number[] = Array.from({length: 101}, (_, i) => i * 5); // 0, 5, 10, ..., 500
  setsOptions: number[] = Array.from({length: 10}, (_, i) => i + 1);    // 1, 2, ..., 10
  repsOptions: number[] = Array.from({length: 20}, (_, i) => i + 1);    // 1, 2, ..., 20


  ngOnInit() {
    this.liftInputForm.get('liftMuscleArea')?.valueChanges.subscribe((muscleArea: string | null) => {
      this.updateExerciseTypes();
    });

    this.liftInputForm.get('liftExerciseType')?.valueChanges.subscribe((exerciseType: string | null) => {
      this.updateExerciseNames(exerciseType);
    });
  }

  updateExerciseTypes() {
    this.exerciseTypes = ['Dumbbell', 'Barbell', 'Machine', 'Body Weight'];
    this.liftInputForm.patchValue({ liftExerciseType: null, liftExerciseName: null });
    this.exerciseNames = [];
    this.showWeightInput = false;
    this.showSetsInput = false;
    this.showRepsInput = false; // Reset the reps input visibility
  }

  updateExerciseNames(exerciseType: string | null) {
    const muscleArea = this.liftInputForm.value.liftMuscleArea;

    if (muscleArea) {
      if (exerciseType === 'Body Weight') {
        this.exerciseNames = muscleArea === 'Upper Body'
          ? ['Push-ups', 'Pull-ups', 'Dips']
          : ['Air Squats', 'Lunges'];
        this.showWeightInput = false;
      } else {
        this.exerciseNames = muscleArea === 'Upper Body'
          ? ['Bench Press', 'Chest Fly', 'Rows', 'Shoulder Press', 'Shoulder Raises', 'Shoulder Shrugs', 'Bicep Hammer Curls', 'Bicep Straight Curls', 'Tricep Extension', 'Tricep Skullcrushers']
          : ['Squat', 'Deadlift', 'Lunges', 'Leg Press', 'Quad Extensions', 'Hamstring Curls', 'Romanian Dead Lifts'];
        this.showWeightInput = true;
      }
      this.liftInputForm.patchValue({ liftExerciseName: null });
      this.showSetsInput = true;
      this.showRepsInput = true; // Show reps input when sets input is shown
    }
  }

  onSubmit() {
    if (this.liftInputForm.valid) {
      const formValue = this.liftInputForm.value;
      const muscleArea = formValue.liftMuscleArea ?? ''; // default to empty string if null or undefined
      const exerciseType = formValue.liftExerciseType ?? '';
      const exerciseName = formValue.liftExerciseName ?? '';
      const exerciseWeight = formValue.liftExerciseWeight ?? 0; // default to 0 if null or undefined
      const exerciseSets = formValue.liftExerciseSets ?? 0;
      const exerciseReps = formValue.liftExerciseReps ?? 0;
  
      const muscleGroup = this.getMuscleGroup(muscleArea, exerciseName);
  
      const submissionData: LiftEntry = {
        liftMuscleArea: muscleArea,
        liftExerciseType: exerciseType,
        liftExerciseName: exerciseName,
        liftExerciseWeight: exerciseWeight,
        liftExerciseSets: exerciseSets,
        liftExerciseReps: exerciseReps,
        muscleGroup
      };
  
      console.log('Lift Form Submitted', submissionData);
      this.dialogRef.close(submissionData);
    }
  }
  
  
  getMinValue(controlName: string): number {
    switch (controlName) {
      case 'liftExerciseWeight':
        return 0;
      case 'liftExerciseSets':
        return 1;
      case 'liftExerciseReps':
        return 1;
      default:
        return 0;
    }
  }

  getMaxValue(controlName: string): number {
    switch (controlName) {
      case 'liftExerciseWeight':
        return 500;
      case 'liftExerciseSets':
        return 10;
      case 'liftExerciseReps':
        return 20;
      default:
        return 100;
    }
  }
  
  getMuscleGroup(muscleArea: string, exerciseName: string): string {
    if (muscleArea === 'Upper Body') {
      switch (exerciseName) {
        case 'Bench Press':
        case 'Push-ups':
        case 'Chest Fly':
          return 'Chest';
        case 'Rows':
        case 'Pull-ups':
          return 'Back';
        case 'Shoulder Press':
        case 'Shoulder Raises':
        case 'Shoulder Shrugs':
          return 'Shoulders';
        case 'Bicep Curls':
        case 'Tricep Extensions':
          return 'Arms';
        default:
          return 'Core';
      }
    } else if (muscleArea === 'Lower Body') {
      switch (exerciseName) {
        case 'Squat':
        case 'Air Squats':
        case 'Leg Press':
        case 'Deadlift':
        case 'Romanian Dead Lifts':
          return 'Hamstrings';
        case 'Hamstring Curls':
          return 'Hamstrings';
        case 'Lunges':
          return 'Glutes';
        case 'Calf Raises':
          return 'Calves';
        default:
          return 'Legs';
      }
    }
    return '';
  }
  

  close() {
    this.dialogRef.close();
  }
}


